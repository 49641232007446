.swiper {
  color: $color-white;
  position: relative;

  .info {
    font-size: 13px;
  }

  .swipe-arrows {
    transform: translateY(-100%);
    position: absolute;
    right: 0;
    top: -22px;

    span {
      border-radius: $border-radius-xs;
      border: 1px solid #c4c4c4;
      justify-content: center;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      color: #c4c4c4;
      height: 26px;
      width: 26px;

      &.active {
        border: 1px solid $color-white;
        color: $color-white;
      }

      i {
        font-size: 12px;
      }
    }
  }
}

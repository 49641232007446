@media (min-width: $screen-sm) {
  .container {
    padding: 0 20px;
  }
  .hide-mobile {
    display: block;
  }
  .hide-tablet {
    display: none;
  }
}

@media (min-width: $screen-md) {
  .container {
    padding: 0 32px;
  }
  .hide-desktop {
    display: none;
  }
  .hide-tablet {
    display: block;
  }
  .main {
    margin-right: 480px;
    margin-left: 64px;
  }
}

@media (min-width: $screen-lg) {
  .container {
    padding: 0 34px;
  }
  .main {
    margin-right: 548px;
    margin-left: 94px;
  }
  .show-desktop-lg {
    display: block;
  }
}

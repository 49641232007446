body {
  margin: 0;
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  //"Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  scrollbar-width: thin;
  scrollbar-color: #666666 auto;
  overflow-y: scroll;
  overflow-x: auto;
  width: 100%;
}

//
//code {
//  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
//}

//#region scroll
body:hover, .vertical-scroll:hover {
  scrollbar-width: thin;
  scrollbar-color: #666666 white
}

::-webkit-scrollbar, .vertical-scroll::-webkit-scrollbar {
  background-color: #e0e0e0;
  width: 5px;
}

::-webkit-scrollbar-thumb, .vertical-scroll::-webkit-scrollbar-thumb {
  border-radius: 50px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #666666;
}

.hidden-scroll::-webkit-scrollbar, .hidden-scroll::-webkit-scrollbar-thumb {
  visibility: hidden;
  height: 0
}

//#endregion
/*

.main {
  margin-left: 0;
  margin-right: 0;
  min-height: 100vh;
  background-color: $white;
  @media only screen and (min-width: 1440px) {
    margin-right: 480px;
    margin-left: 64px;
  }

  @media only screen and (min-width: 1920px) {
    margin-right: 548px;
    margin-left: 94px;
  }

  @media only screen and (min-width: 768px) {
    margin-left: 80px;
  }
  @media only screen and (min-width: 910px) {
    margin-left: 94px;
  }
  @media only screen and (min-width: 1280px) {
    margin-right: 488px;
  }
  @media only screen and (min-width: 1366px) {
    margin-right: 516px;
  }
  @media only screen and (min-width: 1440px) {
    margin-right: 548px;
  }
}
*/

.agent {
  &.main {
    @media only screen and (min-width: 768px) {
      margin-left: 0;
    }
    @media only screen and (min-width: 1280px) {
      margin-left: 94px;
    }
  }
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

input {
  width: 100%;
  border: 0;
}

input:focus, button:focus {
  outline: none;
}

b {
  font-weight: bold;
}

button {
  border: 0;
  outline: none;
  background-color: transparent;
  width: 100%;
  padding: 20px 0;
  cursor: pointer;
  font: normal normal normal 16px/16px $brown;
}

button.disabled {
  border: 1px solid transparent;
  background: $disabledButton;
  color: #646464;
  cursor: auto;
  pointer-events: none;
}

.icons {
  cursor: pointer;
  position: relative;
  display: block;
}

.icons::before {
  content: "";
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  background-color: transparent;
  opacity: 0;
}

.icons:hover::before {
  content: '';
  position: absolute;
  background-color: $black;
  width: 40px;
  height: 40px;
  opacity: 0.06;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.clear-both {
  clear: both;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.pointer {
  cursor: pointer;
}

.hidden {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden !important;
}

.popup-close-icon {
  position: absolute;
  cursor: pointer;
  right: 16px;
  top: 16px;

  i {
    font-size: 24px;
    color: $stoneGray;
  }

  &:hover {
    i {
      color: $doveGray
    }
  }
}

.validation-message {
  color: red;
  font-weight: bold;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.vertical-line {
  border-right: 1px solid #AAAAAA40;
}

.title.light-text {
  font-size: 9px;
  font-family: $SFProtext;
  letter-spacing: -0.03px;
}

/*.background-image, .background {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  display: block;
  z-index: -1;
  width: 100%;
  height: 100%;
}*/

.background {
  background: #1D2223 0 0 no-repeat padding-box;
  opacity: 0.7;
}

.section-with-border {
  border: 1px solid #AAAAAA40;
  border-radius: 4px;
}

.bold-text {
  text-align: left;
  font: normal normal bold 14px/1 $brown;
  letter-spacing: 0;
  color: #1C1C1C;
  opacity: 1;
}

.light-text {
  text-align: left;
  font: normal normal normal 12px/1 $brown;
  letter-spacing: 0;
  color: #6A6A6A;
}

.normal-text {
  text-align: left;
  font: normal normal normal 14px/1 $brown;
  letter-spacing: 0;
  color: #1C1C1C;
}


//#region checkbox
.checkbox-label-container {
  padding: 25px 20px;
  display: flex;
  border-bottom: 1px solid $veryLightGray;
  align-items: center;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    border: none;
    cursor: auto;
  }
}

.checkbox-container {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
}

.checkbox {
  display: none;
}

.checkmark {
  display: inline-block;
  min-width: 16px;
  width: 16px;
  height: 16px;
  background: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 2px;
  box-sizing: border-box;
  margin-right: 12px;
  position: relative;
  vertical-align: baseline;
}

.checkbox-container {
  input:checked ~ .checkmark {
    border-color: black;

    &:after {
      opacity: 1;
      visibility: visible;
      transform: translate(-50%, -50%) scale(1);
      -webkit-transform: translate(-50%, -50%) scale(1);
      -moz-transform: translate(-50%, -50%) scale(1);
      -ms-transform: translate(-50%, -50%) scale(1);
      -o-transform: translate(-50%, -50%) scale(1);
    }
  }

  .checkmark:after {
    content: "";
    width: 8px;
    height: 8px;
    background: black;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    box-sizing: border-box;
    border-radius: 2px;
    -webkit-transform: translate(-50%, -50%) scale(0);
    -moz-transform: translate(-50%, -50%) scale(0);
    -ms-transform: translate(-50%, -50%) scale(0);
    -o-transform: translate(-50%, -50%) scale(0);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    transition: .2s;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -ms-transition: .2s;
    -o-transition: .2s;
  }
}

//#endregion


/*.overlay {
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
}

.overlay.open {
  opacity: 1;
  visibility: visible;
}

.overlay.open .overlay-content {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
}

.overlay-content {
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  bottom: 0;
  z-index: 9;
  background-color: $white;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
}

.overlay-content::-webkit-scrollbar {
  display: none;
}*/

.pop-up.overlay-content {
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
}

// error Massage START
.Toastify__toast {
  padding-right: 30px;
}

.Toastify__close-button {
  position: absolute;
  right: 0;
  top: 10px;
  width: 30px;
}

// error Massage END

.loader{
  & ~ div {
    transition: opacity 120ms;
    opacity: 0.3;
  }
}

.first-letter-up{
  text-transform: capitalize;
}
//@media screen and (min-width: 320px) and (max-width: 1280px) and (orientation: landscape) {
//  html {
//    transform: rotate(-90deg);
//    transform-origin: left top;
//    //width: 100vw;
//    //overflow-x: hidden;
//    position: absolute;
//    top: 100%;
//    left: 0;
//  }
//}

//@media only screen and (orientation:landscape){
//  body {
//    transform: rotate(90deg);
//    width: 100vw;
//    height: 100vh;
//  }
//}



// this removes the scroll functionality in firefox which was written to open overlay
@-moz-document url-prefix() {
  body {
    top: 0 !important;
    position: relative !important;
    overflow-y: scroll !important;
  }
}
// this removes the scroll functionality in firefox which was written to open overlay

h1 {
  font-family: $font-primary-bold;
  line-height: 28px;
  font-size: 24px;
}

h2 {
  font-family: $font-primary-bold;
  line-height: 42px;
  font-size: 30px;
}

h3 {
  font-family: $font-tertiary;
  line-height: 20px;
  font-size: 15px;
}

h4 {
  font-family: $font-primary-bold;
  line-height: 20px;
  font-size: 15px;
}

h5 {
  font-family: $font-primary-bold;
  line-height: 17px;
  font-size: 14px;
}

h6 {
  font-family: $font-primary-bold;
  line-height: 17px;
  font-size: 14px;
}

.text-uppercase {
  text-transform: uppercase;
}

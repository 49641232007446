$colors: (
        secondary:$color-secondary,
        primary: $color-primary,
        black: $color-black,
        white:$color-white,
        light: $text-light,
        gray: $color-gray,
);

@each $color, $hex in $colors {
  .color-#{$color} {
    color: $hex;
  }
}

$fonts: (
        primary: $font-primary,
        primary-bold: $font-primary-bold,
        secondary: $font-secondary,
        tertiary: $font-tertiary,
);

@each $font, $family in $fonts {
  .font-#{$font} {
    font-family: $family;
  }
}

.flyout {
  transition: 0.3s;
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba($color-black, 0.45);

  &.open {
    opacity: 1;
    visibility: visible;

    & .flyout-content {
      transform: translateX(0);
    }
  }

  .flyout-content {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    z-index: 9;
    overflow-y: auto;
    scrollbar-width: none;
    transition: 0.5s;
    transform: translateX(100%);
    background-color: $bg-light;
    box-shadow: $shadow-sm;
    padding: 24px 16px;
  }

  .flyout-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .flyout-title {
      display: inline-block;
      font-family: $font-tertiary;
      font-size: 15px;
      line-height: 20px;
      min-height: 20px;
    }

    .flyout-close {
      position: absolute;
      top: 8px;
      right: 8px;
      display: flex;
      align-self: flex-start;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      text-align: center;
      font-size: 12px;
      line-height: 40px;
      cursor: pointer;
      transition: $transition-xs;

      &:hover {
        background-color: rgba(0, 0, 0, 0.06);
      }
    }
  }
}

@media (min-width: $screen-sm) {
  .user-menu {
    .header-avatar {
      &.placeholder {
        font-size: 14px;
      }
    }
  }
}

@media (min-width: $screen-md) {
  .user-menu {
    height: 84px;
  }
  .data-name {
    max-width: 200px;
  }
}

@media (min-width: $screen-lg) {
  .user-menu {
    height: 100px;

    .header-avatar {
      &.placeholder {
        line-height: 20px;
        font-size: 16px;
      }
    }
  }
  .data-name {
    max-width: 180px;
  }
}

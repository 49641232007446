@media (min-width: $screen-sm) {
  .agent-header {
    padding-bottom: 24px;
    padding-top: 20px;
  }

  .agent-banner {
    padding: 20px;
  }

  .agent-image {
    margin-right: 20px;
    height: 68px;
    width: 68px;
  }

  .agent-title {
    line-height: 38px;
    font-size: 24px;
  }

  .agent-sup-info {
    letter-spacing: 1.4px;
  }

  .agent-sub-info {
    letter-spacing: -0.04px;
    max-width: 100%;
  }

  .agent-sup-info, .agent-sub-info {
    line-height: 22px;
    font-size: 14px;
  }

  .transactions-header {
    margin-top: 20px;
    padding: 0 20px 15px;
  }

  .agent-sticky-bar {
    top: 84px;
  }

  .transactions-list-width-order {
    li {
      &:nth-child(1) {
        width: 8%;
      }
      &:nth-child(2) {
        width: 40%;
      }
      &:nth-child(3) {
        width: 20%;
      }
      &:nth-child(4) {
        width: 26%;
      }
      &:nth-child(5) {
        width: 35%;
      }
      &:nth-child(6) {
        width: 18%;
      }
      &:nth-child(7) {
        width: 10%;
      }
    }
  }
}

@media (min-width: $screen-md) {
  .agent-header {
    background-image: none;
    padding-bottom: 0;
    padding-top: 0;
  }

  .transactions-header {
    margin-top: 24px;
    padding: 0 20px 13px;
  }
}

@media (min-width: $screen-lg) {
  .agent-banner {
    padding: 24px;
  }

  .agent-sticky-bar {
    top: 100px;
  }

  .agent-image {
    margin-right: 24px;
    height: 76px;
    width: 76px;
  }

  .agent-title {
    line-height: 42px;
    font-size: 30px;
  }

  .agent-sup-info {
    letter-spacing: 1.6px;
  }

  .agent-sub-info {
    letter-spacing: -0.05px;
  }

  .agent-sup-info, .agent-sub-info {
    line-height: 26px;
    font-size: 16px;
  }

  .transactions-header {
    margin-top: 32px;
    padding: 0 20px 20px;

    p {
      line-height: 13px;
      font-size: 12px;
    }
  }
}
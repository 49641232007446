@media (min-width: $screen-sm) {
  .right-side {
    .due-date-title {
      font-size: 18px;
      margin-bottom: 16px;
    }

    .due-date-description {
      font-size: 14px;
    }
  }

  .data-img {
    height: 44px;
    width: 44px;
  }
}

@media (min-width: $screen-md) {
  .right-side {
    display: block;
    height: 312px;
    width: 384px;
    right: 48px;
    bottom: 0;
    top: 0;

    .escrow-card {
      margin-bottom: 28px;
      height: 282px;
    }

    .due-date-title {
      letter-spacing: 0.63px;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 12px;
    }

    .due-date-description {
      line-height: 22px;
    }

    .right-side-back-btn {
      display: none;
    }
  }
}

@media screen and (min-width: $screen-lg) {
  .right-side {
    width: 424px;
    top: 0;

    .escrow-card {
      margin-bottom: 48px;
      height: 344px;
    }

    .due-date-title {
      letter-spacing: 0.77px;
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 20px;
    }

    .due-date-description {
      font-size: 16px;
      line-height: 26px;
    }
  }

  .data-img {
    height: 48px;
    width: 48px;
  }
}
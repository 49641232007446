@import '../abstracts/mixins';

.header_calendar {
  position: relative;
  margin-right: 50px;

  .calendar_container {
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 6;

    background: #fff;

    @media only screen and (min-width: 428px) {
      //width: auto;
      //position: absolute;
      //right: -140px;
    }
    @media only screen and (min-width: 768px) {
      //position: absolute;
      width: auto;
    }
    @media only screen and (min-width: 910px) {
      //position: absolute;
      right: 33px;
      top: 28px;
    }
    @media only screen and (min-width: 1280px) {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .rdrDateRangePickerWrapper {
    width: 100%;
    box-shadow: 0 0 6px #00000026;
    padding: 0;
    @media only screen and (min-width: 768px) {
      width: auto;
      //padding: 24px 0 46px;
    }
  }

  .rdrCalendarWrapper {
    width: 100%;
    padding: 29px 21px 23px 21px;

    @media only screen and (min-width: 428px) {
      //width: 330px;
      padding: 44px 51px 59px 50px;
    }

    @media only screen and (min-width: 768px) {
      width: 424px;
      padding: 42px 48px 60px 50px;
    }

    @media only screen and (min-width: 910px) {
      padding: 44px 48px 51px 49px;
    }

    @media only screen and (min-width: 910px) {
      padding-top: 42px;
    }

    .rdrMonth {
      width: 100%;
      padding: 0;
    }
  }

  .rdrDefinedRangesWrapper {
    display: none;
  }

  /*------- Calendar header part start-------*/

  .rdrMonthAndYearWrapper {
    position: relative;
    height: auto;
    //padding: 30px 21px 27px 21px;
    padding: 0;
    margin-bottom: 20px;
    @media only screen and (min-width: 428px) {
      margin-bottom: 34px;
    }
    @media only screen and (min-width: 768px) {
      //padding-left: 56px;
      //padding-right: 46px;
    }
  }

  .rdrMonthAndYearPickers {
    font-size: 20px;
    font-weight: bold;
    font-family: $brown;
    justify-content: flex-start !important;
  }

  .rdrNextPrevButton {
    background-color: transparent !important;
    margin: 0 !important;
    position: relative;
    width: 9px;
    height: 17px;

    i {
      display: none !important;
    }

    &::before {
      width: 100%;
      height: 100%;
      color: #787C85;
      position: absolute;
      content: '';
      //@include calendar-arrow(#787C85);
      background-image: url('../../../images/left_arrow.svg');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: block;
      top: 0
      //transform: rotate(135deg);
      //border-right: 2px solid;
      //margin-top: -10px;
    }

    //&::after {
    //  margin-top: -1px;
    //  transform: rotate(45deg);
    //}
  }

  .rdrPprevButton {
    position: absolute;
    top: 1px;
    right: 32px;

  }

  .rdrNextButton {
    transform: rotate(180deg);
  }

  /*------- Calendar header part end-------*/


  /*------- Calendar body part start-------*/
  .rdrDay {
    height: 37px;
    width: 37px;

    margin-bottom: 6px;
    margin-right: 3px;
    //margin-left: 1px;
    &:nth-of-type(7n) {
      margin-right: 0;
    }

    @media only screen and (min-width: 428px) {
      height: 44px;
      width: 44px;
    }
  }

  .rdrWeekDay {
    font-family: $brown;
    font-size: 12px;
  }

  .rdrDayNumber {
    width: 100%;
    height: 100%;
    position: initial;

    span {
      //position: relative;
      z-index: 2;
      font-size: 15px;
      font-family: $SFProtext;
      font-weight: normal;
      //
      &::after {
        content: none !important;
      }
    }
  }

  .rdrStartEdge, .rdrInRange, .rdrEndEdge, .rdrDayStartPreview, .rdrDayEndPreview, .rdrDayInPreview {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    border-radius: 100% !important;
    border: 1px dashed $lightgreen;
  }

  //.rdrDayHovered{
  //    border: none!important;
  //}
  /*------- Calendar body part start-------*/

  /*------- Calendar frames start -------*/

  .extra_frame {
    height: 100%;
    //width: calc(100% - 4px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    //position: absolute;
    //top: 0;
    //right: 0;
    //left: 2px;
    //margin-left: 2px;
    //margin-right: 2px;
    z-index: 1;

    border-radius: 100%;
  }

  .green_frame {
    border: 1px solid $lightgreen;
  }

  .grey_frame {
    border: 1px solid $lightGray;
  }

  .red_frame {
    color: $white !important;

    background: $red;
    //span {
    //    color: $white !important;
    //}
  }

  @media only screen and (min-width: 1280px) {
    margin-right: 0;
  }
}

/*------- Calendar frames end -------*/

.btn {
  text-transform: capitalize;
  background-image: none;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  user-select: none;
  border-radius: 0;
  cursor: pointer;
  padding: 10px;
  outline: none;
  width: 100%;

  //button types
  &.btn-primary {
    background-color: $color-black;
    font-family: $font-secondary;
    color: $color-white;
    position: relative;
    border: none;

    &:after{
      content:"";
      background-color: $color-success;
      transition: width 1s ease;
      position: absolute;
      display:block;
      height: 4px;
      left: 0;
      bottom:0;
      width: 0;
    }

    &:hover:after{
      width: 100%;
    }
  }
  &.btn-outline {
    border: 1px solid $color-black;
    background-color: transparent;
    transition: background-color 1s ease;
    color: $text-light;

    &:hover {
      background-color: $bg-hover;
    }
  }
  &.disabled {
    background: $bg-disabled;
    color: $color-disabled;
    pointer-events: none;
    border: none;
    cursor: auto;
  }
  &.with-left-icon {
    i {
      vertical-align: middle;
      color: $color-black;
      margin-right: 12px;
      font-size: 20px;
    }
  }
  //button sizes
  &.xs {
    line-height: 14px;
    font-size: 12px;
    height: 40px;
  }
  &.sm {
    line-height: 24px;
    font-size: 12px;
    height: 48px;
  }

  &.md {
    line-height: 24px;
    font-size: 16px;
    height: 56px;
  }
}

@media (min-width: $screen-sm) {
  .card {
    background-color: rgba($color-white, 0.9);
    padding: 32px;
  }
}

@media screen and (min-width: $screen-lg) {
  .card {
    padding: 42px 32px;
  }
}

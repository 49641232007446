.milestones-section {
  background-color: $bg-light;
  padding-bottom: 30px;
}

.milestone-title {
  box-shadow: 0 1px 1px $color-white,  0 -1px 1px $color-white;
  background: $bg-light;
  padding-bottom: 14px;
  padding-top: 16px;
}

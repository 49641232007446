@font-face {
  font-family: 'SFProText Regular';
  src: url('../../fonts/SFProText-Regular/SFProText-Regular.eot');
  src: url('../../fonts/SFProText-Regular/SFProText-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/SFProText-Regular/SFProText-Regular.woff2') format('woff2'),
  url('../../fonts/SFProText-Regular/SFProText-Regular.woff') format('woff'),
  url('../../fonts/SFProText-Regular/SFProText-Regular.ttf') format('truetype'),
  url('../../fonts/SFProText-Regular/SFProText-Regular.svg#euclid_circular_alight') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'SFProText Bold';
  src: url('../../fonts/SFProText-Bold/SFProText-Bold.eot');
  src: url('../../fonts/SFProText-Bold/SFProText-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/SFProText-Bold/SFProText-Bold.woff2') format('woff2'),
  url('../../fonts/SFProText-Bold/SFProText-Bold.woff') format('woff'),
  url('../../fonts/SFProText-Bold/SFProText-Bold.ttf') format('truetype'),
  url('../../fonts/SFProText-Bold/SFProText-Bold.svg#euclid_circular_alight') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Brown Regular';
  src: url('../../fonts/Brown-Regular/Brown-Regular.eot');
  src: url('../../fonts/Brown-Regular/Brown-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Brown-Regular/Brown-Regular.woff2') format('woff2'),
  url('../../fonts/Brown-Regular/Brown-Regular.woff') format('woff'),
  url('../../fonts/Brown-Regular/Brown-Regular.ttf') format('truetype'),
  url('../../fonts/Brown-Regular/Brown-Regular.svg#euclid_circular_alight') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Brown Bold';
  src: url('../../fonts/Brown-Bold/Brown-Bold.eot');
  src: url('../../fonts/Brown-Bold/Brown-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Brown-Bold/Brown-Bold.woff2') format('woff2'),
  url('../../fonts/Brown-Bold/Brown-Bold.woff') format('woff'),
  url('../../fonts/Brown-Bold/Brown-Bold.ttf') format('truetype'),
  url('../../fonts/Brown-Bold/Brown-Bold.svg#euclid_circular_alight') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'BrandonGrotesque Bold';
  src: url('../../fonts/BrandonGrotesque-Bold/BrandonGrotesque-Bold.eot');
  src: url('../../fonts/BrandonGrotesque-Bold/BrandonGrotesque-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/BrandonGrotesque-Bold/BrandonGrotesque-Bold.woff2') format('woff2'),
  url('../../fonts/BrandonGrotesque-Bold/BrandonGrotesque-Bold.woff') format('woff'),
  url('../../fonts/BrandonGrotesque-Bold/BrandonGrotesque-Bold.ttf') format('truetype'),
  url('../../fonts/BrandonGrotesque-Bold/BrandonGrotesque-Bold.svg#euclid_circular_alight') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

.modal-overlay {
  background-color: rgba($color-black, 0.45);
  transition: $transition-xs;
  position: fixed;
  z-index: 9;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

.modal-container {
  transform: translate(-50%, -50%);
  border-radius: $border-radius-sm;
  background-color: $color-white;
  box-shadow: $shadow-lg;
  position: fixed;
  left: 50%;
  top: 50%;

  &.xs {
    padding: 16px 20px 15px;
    min-height: 195px;
    width: 270px;

    .modal-close {
      right: 8px;
      top: 8px;
    }

    .modal-title {
      font-family: $font-primary-bold;
      letter-spacing: -0.04px;
      margin-bottom: 10px;
      padding-bottom: 3px;
      line-height: 20px;
      font-size: 15px;

      &.border-bottom{
        border-bottom: 1px solid $color-gray-medium;
      }
    }

    .modal-description {
      font-family: $font-secondary;
      letter-spacing: -0.04px;
      margin-bottom: 9px;
      color: $text-light;
      line-height: 14px;
      font-size: 12px;
    }

    .message-field {
      border: 1px solid $color-gray-medium;
      font-family: $font-secondary;
      color: $color-gray40;
      margin-bottom: 10px;
      padding: 10px 12px;
      line-height: 19px;
      border-radius: 0;
      font-size: 13px;
      outline: none;
      height: 54px;
      resize: none;
      width: 100%;

      &:focus, &:focus-visible {
        border: 1px solid $color-black;
        color: $text-primary;
      }
    }

    .modal-body {
      .btn {
        font-family: $font-primary;
        text-transform: uppercase;
      }
    }
  }

  &.sm {
    padding: 26px 36px 30px 24px;
    width: 310px;

    .modal-close {
      right: 12px;
      top: 12px;
    }

    .modal-title {
      font-family: $font-tertiary;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.04px;
      margin-bottom: 10px;
      color: $text-primary;

      &.toUpperCase{
        text-transform: uppercase;
      }
    }

    .modal-description {
      font-family: $font-secondary;
      font-size: 13px;
      line-height: 21px;
      letter-spacing: -0.04px;
      color: $text-light;
    }
  }

  &.md {
    padding: 36px 30px 30px;
    width: 320px;

    .modal-close {
      right: 18px;
      top: 18px;
    }
  }

  .modal-close {
    position: absolute;
    cursor: pointer;

    i {
      transition: $transition-xs;
      color: $color-gray-light;
      font-size: 24px;
    }

    &:hover {
      i {
        color: $text-light;
      }
    }
  }

  .avatar-img {
    border-radius: 50%;
    margin-bottom: 7px;
    margin-right: auto;
    margin-left: auto;
    height: 88px;
    width: 88px;
  }

  .client-title {
    font-family: $font-primary-bold;
    text-align: center;
    margin-bottom: 3px;
    line-height: 24px;
    font-size: 17px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .client-position {
    margin-bottom: 25px;
    text-align: center;
    color: $text-light;
    line-height: 20px;
    font-size: 12px;
  }
}

.contact-modal-body {
  .contact-info {
    border-bottom: 1px solid rgba($color-gray, 0.25);
    padding-bottom: 8px;
    margin-bottom: 22px;
    line-height: 20px;
    font-size: 12px;
  }
  .personal-info {
    align-items: center;
    display: flex;

    &:not(:last-child) {
      margin-bottom: 19px;
    }

    p {
      transition: $transition-xs;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-bottom: 2px;
      position: relative;
      line-height: 20px;
      overflow: hidden;
      cursor: default;
      font-size: 15px;

      &:hover {
        &:before {
          content: "";
          background-color: $color-primary;
          position: absolute;
          height: 1px;
          width: 100%;
          bottom: 0;
          left: 0;
        }
      }
    }

    i {
      margin-right: 12px;
      font-size: 20px;

    }
  }
}
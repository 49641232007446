.address-container {
  display: flex;
  flex-direction: column;

  div {
    font-size: 14px;
    line-height: 18px;
  }
  p {
    font-size: 12px;
    line-height: 15px;
    color: $text-light;
  }
}

@media screen and (min-width: $screen-sm) {
  .progress-bar {
    top: -115px;

    .content {
      height: 332px;
    }

    .title-container {
      h6 {
        margin-bottom: 15px;
      }

      h1 {
        font-size: 32px;
        line-height: 42px;
      }
    }

    .progress-inner {
      padding: 23px 20px 18px;
    }

    .swipe-arrows {
      display: none;
    }

    .congrats {
      letter-spacing: 1.5px;
    }

    .step-name {
      margin-top: 12px;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.21px;
    }

    .transaction-address-dropdown {
      margin-bottom: 17px;
      padding-right: 0;
    }

    .address-info {
      font-size: 13px;
      line-height: 18px;
    }
  }

  .progress-bar-card {
    padding-top: 68%;

    .progress-bar-card-content {
      .progress-step {
        .step-name {
          margin-top: 8px;
          font-size: 14px;
          line-height: 14px;
          letter-spacing: 0.21px;
        }
      }
    }

    h4 {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.4px;
    }

    p {
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.33px;
    }
  }
}

@media screen and (min-width: $screen-md) {
  .progress-bar {
    top: -95px;

    .content {
      height: 344px;
    }

    .progress-inner {
      padding: 27px 32px;
    }

    .progress-steps-item {
      /*&:hover {
        .step-box.fill-25, .step-box.fill-50, .step-box.fill-75 {
          height: 20px;

          .step-value {
            width: auto;
            opacity: 1;
            transform: translate(6px, 0);
          }
        }
      }*/
    }

    .transaction-address-dropdown {
      margin-bottom: 0;
    }

    .address-info {
      margin-bottom: 0;
    }

    .progress-steps {
      min-height: 60px;
    }
  }

  .progress-bar-card {
    .progress-bar-card-content {
      bottom: 16px;
      left: 24px;
      right: 24px;

      .progress-step {
        transition: $transition-xs;
        cursor: default;
        margin-right: 3px;

        .step-name {
          margin-top: 12px;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 1.05px;
        }
      }
    }
  }
}

@media screen and (min-width: $screen-lg) {
  .progress-bar {
    //padding-bottom: 30px;
    top: -130px;

    .content {
      height: 400px;
    }

    .title-container {
      h6 {
        margin-bottom: 23px;
      }

      h1 {
        font-size: 38px;
        line-height: 42px;
      }
    }

    .progress-inner {
      padding: 39px 34px 34px;
    }

    .congrats {
      letter-spacing: 1.6px;
    }

    .transaction-address-dropdown {
      margin-bottom: 5px;
    }

    .step-name {
      margin-top: 18px;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.7px;
    }
  }

  .progress-bar-card {
    padding-top: 66%;

    .progress-bar-card-content {
      bottom: 24px;
      left: 32px;
      right: 32px;

      .progress-step {
        .step-name {
          margin-top: 14px;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: 1.13px;
        }
      }

      h4 {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.5px;
      }

      p {
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 1.13px;
      }
    }
  }
}
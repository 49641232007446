.flex{
    display: flex;
  }

.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end{
  display: flex;
  justify-content: center;
  align-items: end;
}


.flex-column-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-space-between{
  display: flex;
  justify-content: space-between;
}
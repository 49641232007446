.history-chart {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.history-section-chart {
  cursor: pointer;
  margin-bottom: 16px;
  width: 64px;
  height: 64px;

  .price {
    position: absolute;
    color: $color-primary;
    font-family: $font-tertiary;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: -0.04px;
  }

  canvas {
    // width: 100% !important;
    // height: 100% !important;
    background: $color-white;
    border-radius: 50%;
  }

  &.blurred {
    filter: blur(2px);
  }
}

// flyout part styles
.history-body {
  .popup-chart {
    width: 148px;
    height: 148px;
    margin: 28px auto 32px;

    .price {
      position: absolute;
      color: $color-primary;
      font-family: $font-tertiary;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.3px;
    }

    canvas {
      // width: 100% !important;
      // height: 100% !important;
    }

    &.blurred {
      filter: blur(5px);
    }
  }

  .title-description {
    display: flex;
    flex-direction: column;
    text-align: center;

    .title {
      font-family: $font-tertiary;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 0.63px;
      margin-bottom: 8px;
    }
    .description {
      font-family: $font-secondary;
      color: $text-light;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

// flyout part styles
.history-collapse-panel {
  padding: 16px;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .divider {
    margin: 17px auto;
  }

  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    & > div {
      min-width: 30%;

      div {
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        color: $text-light;
        font-family: $font-secondary;
      }

      span {
        font-size: 14px;
        line-height: 18px;
        color: $color-primary;

        &.bold_in_mobile {
          font-family: $font-primary-bold;
        }
      }

      &:last-child {
        min-width: 40%;
      }
    }
  }
}

.progress-bar {
  background-color: $bg-light;
  box-shadow: 0 1px 3px $color-white;
  position: sticky;
  z-index: 10;
  top: -45px;

  .content {
    position: relative;
    height: 240px;
    width: 100%;

    &:before {
      content: "";
      background: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      );
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1;
      left: 0;
      top: 0;
    }
  }

  .progress-bar-container {
    &.container {
      @media (max-width: $screen-md - 1px) {
        padding: 0;
      }
    }
  }

  .background-image {
    width: 100%;
    height: 100%;
  }

  .progress-inner {
    justify-content: space-between;
    padding: 20px 16px 18px;
    flex-direction: column;
    position: absolute;
    overflow: hidden;
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 2;
    top: 0;
  }

  .title-container {
    color: $color-white;

    h6 {
      margin-bottom: 13px;
    }

    h1 {
      margin-bottom: 18px;
      font-size: 24px;
      line-height: 28px;
    }
  }

  .transaction-address-dropdown {
    margin-bottom: 15px;
    padding-right: 60px;

    &-value {
      max-width: calc(100vw - 130px);

      h4 {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .address-info {
    font-size: 12px;
    line-height: 20px;
    color: rgba($color-white, 0.8);
  }

  .swipe-arrows {
    display: block;
  }
}

.progress-bar-card {
  position: relative;
  min-width: 314px;
  min-height: 208px;
  border-radius: 4px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #ffffff;
  padding-top: 60%;
  cursor: pointer;

  .progress-bar-card-content {
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
    overflow: hidden;

    .progress-step {
      transition: $transition-xs;
      cursor: default;
      margin-right: 3px;

      .step-box {
        position: relative;
        display: flex;
        background-color: $color-step;
        transition: $transition-xs;
        width: 100%;
        height: 4px;
        overflow: hidden;

        .step-line {
          background: $color-step-filled;
          height: 100%;
        }

        .step-value {
          font-family: $font-secondary-bold;
          transform: translate(6px, 100%);
          transition: all 0.1s ease-in;
          color: $color-secondary;
          opacity: 0;
          width: 0;
        }
      }

      .step-name {
        font-family: $font-tertiary;
        text-transform: uppercase;
        margin-top: 10px;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.18px;
      }
    }

    h4 {
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.38px;
    }

    p {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.3px;
      color: rgba($color-white, 0.8);
      margin-bottom: 16px;
    }
  }
}

.congrats {
  letter-spacing: 1.4px;
}

.progress-steps {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .step-box {
    display: flex;
  }

  .progress-steps-item {
    transition: $transition-xs;
    width: calc(25% - 3px);
    min-width: 170px;
    cursor: default;

    &:not(:last-child) {
      margin-right: 3px;
    }

    .step-box {
      position: relative;
      display: flex;
      background-color: $color-step;
      transition: $transition-xs;
      width: 100%;
      height: 4px;
      overflow: hidden;

      .step-line {
        background: $color-step-filled;
        height: 100%;
      }

      .step-value {
        font-family: $font-secondary-bold;
        transform: translate(6px, 100%);
        transition: all 0.1s ease-in;
        color: $color-secondary;
        opacity: 0;
        width: 0;
      }

      &.fill-25 {
        .step-line {
          width: 25%;
        }
      }

      &.fill-33 {
        .step-line {
          width: 33.3%;
        }
      }

      &.fill-50 {
        .step-line {
          width: 50%;
        }
      }

      &.fill-67 {
        .step-line {
          width: 66.6%;
        }
      }

      &.fill-75 {
        .step-line {
          width: 75%;
        }
      }

      &.fill-100 {
        .step-line {
          width: 100%;
        }
      }
    }
  }

  .step-name {
    margin-top: 10px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.18px;
    display: flex;
    align-items: center;

    .icon-info{
      margin-left: 4px;
      cursor: pointer;
    }
  }
}

.transaction-address-dropdown-option {
  display: flex;
  align-items: center;

  .house-img {
    border-radius: $border-radius-xs;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    height: 40px;
    width: 40px;
  }

  &-address {
    width: 217px;

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}


.banner {
  //position: sticky;
  //top: -40px;
  padding-bottom: 18px;
  background-color: white;
  z-index: 1;

  @media only screen and (min-height: 400px) {
    position: sticky;
    top: -40px;
  }
    @media only screen and (min-width: 428px) {
    top: -80px;
  }

  @media only screen and (min-width: 768px) {
    padding: 0 34px;
    top: -70px;
  }

  @media only screen and (min-width: 910px) {
    //padding: 0 34px;
  }

  &-background {
    background: transparent linear-gradient(270deg, #000000D0 0%, #000000BF 100%) 0 0 no-repeat padding-box;
    opacity: 0.7;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &-img {
    position: relative;
    width: 100%;
    height: 256px;
    @media only screen and (min-width: 428px) {
      height: 340px;
    }

    @media only screen and (min-width: 768px) {
      height: 340px;
    }

    @media only screen and (min-width: 1440px) {
      height: 400px;
    }
    @media only screen and (min-width: 1680px) {
      height: 400px;
    }
  }

  &-title-swiper {
    position: absolute;
    //padding: 24px 10px 0 10px;
    width: 100%;
    height: 100%;
    top: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  &-title-container {
    margin: 24px 10px 0 10px;
    @media only screen and (min-width: 428px) {
      margin: 40px 20px 0 20px;
    }
    @media only screen and (min-width: 768px) {
      margin: 40px 32px 0 32px;
    }
  }

  &-subTitle {
    font: normal normal normal 14px/24px $brown;
    letter-spacing: 1.4px;
    color: $white;
    text-transform: uppercase;
    @media only screen and (min-width: 428px) {
      font: normal normal normal 18px/24px $brown;
      letter-spacing: 1.8px;
    }
  }

  &-title {
    font: normal normal bold 24px/28px $brown;
    color: $white;
    margin-bottom: 10px;
    @media only screen and (min-width: 428px) {
      font: normal normal bold 32px/40px $brown;
      max-width: 450px;
    }
    @media only screen and (min-width: 768px) {
      font: normal normal bold 36px/40px $brown;
    }
    @media only screen and (min-width: 1440px) {
      font: normal normal bold 42px/43px $brown;
      max-width: 540px;
    }
  }

  &-swiper {
    margin: auto 0 11px 10px;
    //margin: auto 10px 0 10px;
    @media only screen and (min-width: 428px) {
      //margin: 50px 0 0 20px;
      //margin: auto 20px 0 20px;
      margin-left: 20px;
      margin-bottom: 24px;
    }

    @media only screen and (min-width: 768px) {
      margin: auto 0 38px 0;
    }

    @media only screen and (min-width: 910px) {
      margin-bottom: 22px;
    }

    @media only screen and (min-width: 1680px) {
      margin-bottom: 40px;
    }

    &-title {
      font: normal normal bold 16px/24px $brown;
      letter-spacing: 0.4px;
      color: $white;

      @media only screen and (min-width: 768px) {
        margin-left: 32px;
      }
      @media only screen and (min-width: 1440px) {
        margin-bottom: 25px;
      }
      @media only screen and (min-width: 1680px) {
        margin-bottom: 10px;
      }
    }
  }

  /*.hide-completed {
    font: normal normal normal 13px/15px $brown;
    justify-content: flex-end;
    display: flex;
    margin: 16px 10px 0 0;

    .button-section {
      background-color: black;
      border-radius: 18px;
      width: 32px;
      height: 18px;
      cursor: pointer;
      margin-left: 8px;
      position: relative;

      &.active {
        background-color: $lightgreen;

        .button {
          left: calc(100% - 18px);
        }
      }

      .button {
        -webkit-transition: left 0.5s ease-in-out;
        -moz-transition: left 0.5s ease-in-out;
        -ms-transition: left 0.5s ease-in-out;
        -o-transition: left 0.5s ease-in-out;
        pointer-events: none;
        top: 2px;
        left: 2px;
        height: 14px;
        width: 14px;
        border-radius: 100%;
        background-color: white;
        position: absolute;
      }
    }

    @media only screen and (min-width: 428px) {
      margin-top: 24px;
    }
  }*/
}


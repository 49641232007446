.overlay {
  background-color: rgba($color-black, 0.3);
  transition: $transition-xs;
  visibility: hidden;
  position: fixed;
  z-index: 9;
  opacity: 0;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  &.open {
    opacity: 1;
    visibility: visible;

    .overlay-content {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .overlay-content {
    background-color: $color-white;
    transform: translateX(100%);
    transition:$transition-xs;
    scrollbar-width: none;
    overflow-y: auto;
    position: fixed;
    width: 320px;
    z-index: 9;
    bottom: 0;
    right: 0;
    top: 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.user-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .user-info-wrapper {
    -webkit-tap-highlight-color: transparent;
    justify-content: flex-end;
    align-items: center;
    width: fit-content;
    text-align: right;
    cursor: pointer;
    display: flex;
  }

  .header-avatar {
    &.placeholder {
      line-height: 26px;
      font-size: 13px;
    }
  }
}

.data-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 370px;
}
.empty-data-message {
  border-radius: $border-radius-xs;
  background-color: $color-white;
  box-shadow: $shadow-xs;
  margin-right: 16px;
  text-align: center;
  margin-left: 16px;
  padding: 24px;

  .title {
    text-transform: uppercase;
    letter-spacing: 0.56px;
    margin-right: auto;
    margin-bottom: 8px;
    margin-left: auto;
    line-height: 18px;
    max-width: 270px;
    font-size: 16px;
  }

  .description {
    font-family: $font-secondary;
    margin-right: auto;
    color: $text-light;
    margin-left: auto;
    line-height: 18px;
    max-width: 250px;
    font-size: 12px;
    letter-spacing: -0.04px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 10px;
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: $white;

  @media only screen and (min-width: 428px) {
    padding: 25px 20px;
  }
  @media only screen and (min-width: 768px) {
    padding: 22px 34px;
  }
  @media only screen and (min-width: 910px) {
    padding: 28px 34px;
  }

  &_logo {
    //min-width: 100px;
    //width: 100px;
    height: 36px;
    cursor: pointer;

    img {
      width: auto;
    }

    @media only screen and (min-width: 428px) {
      //width: 140px;
      height: 51px;
    }
    @media only screen and (min-width: 768px) {
      //width: 157px;
      height: 57px;
    }
    @media only screen and (min-width: 910px) {
      //width: 180px;
      height: 65px;
    }
  }

  //&_right {
  //  display: flex;
  //  justify-content: center;
  //  align-items: center;
  //  margin: -7px;
  //  @media only screen and (min-width: 428px) {
  //    margin: -12px;
  //  }
  //  @media only screen and (min-width: 768px) {
  //    margin: -16px;
  //  }
  //}

  &_icons_svg {
    @media only screen and (min-width: 1280px) {
      color: $milkColor;
    }
  }

  &_right_icons {
    margin: 7px;
    cursor: pointer;
    @media only screen and (min-width: 428px) {
      margin: 12px;
    }
    @media only screen and (min-width: 768px) {
      margin: 16px;
    }
  }

  &_right_Packet_container {
    @media only screen and (min-width: 1280px) {
    }
  }

  &_calendar_text_block {
    display: none;
    //max-width: 200px;
    font: normal normal bold 16px/24px $brown;
    color: $black;
    opacity: 0.8;
    cursor: pointer;
    @media only screen and (min-width: 1280px) {
      display: block;
    }

    &:hover {
      opacity: 1;
    }
  }

  .arrow_down {
    margin-left: 11px;
  }

  .notif-message {
    position: absolute;
    width: 20px;
    height: 20px;
    background: $green;
    top: -50%;
    left: 50%;
    border-radius: 50%;
    font: normal normal normal 11px/12px $brown;
    color: $white;
  }

  .calendar_icon {
    display: block;
    @media only screen and (min-width: 1280px) {
      display: none;
    }
  }

  .search_text {
    position: absolute;
    margin-left: 14px;
    color: $white;
    font: normal normal 300 14px/14px $brown;
  }

  &_right_Packet {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    position: fixed;
    right: 10px;
    top: 20px;

    .overlay {
      z-index: 15;
    }

    @media only screen and (min-width: 428px) {
      right: 20px;
      top: 30px;
    }

    @media only screen and (min-width: 768px) {
      right: 35px;
    }

    @media only screen and (min-width: 910px) {
      top: 40px;
    }

    @media only screen and (min-width: 1280px) {
      z-index: 9;
      width: 488px;
      //justify-content: space-between;
      justify-content: flex-end;
      position: initial;
      right: 0;
      padding: 34px;
      top: 0;
      height: 134px;
    }
    @media only screen and (min-width: 1366px) {
      width: 516px;
    }
    @media only screen and (min-width: 1440px) {
      width: 548px;
    }
  }
}

.header_user_data {
  cursor: pointer;

  &_name {
    font: normal normal normal 15px/15px $brown;
    color: $white;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 132px;
    padding-bottom: 5px;
  }

  &_role {
    font: normal normal normal 12px/15px $SFProtext;
    color: $white;
  }

  &_text {
    display: none;
    @media only screen and (min-width: 1280px) {
      display: block;
    }
  }

  &_img_container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 16px;
  }

  &_img {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
}

@media (min-width: $screen-sm) {
  .transaction-details {
    padding: 0 20px 17px;
    min-height: 460px;

    .milestones-section {
      .container {
        padding: 0 20px;
      }
    }
  }
  .related-users {
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 18px;

    & > div {
      width: calc(50% - 10px);
      margin-bottom: 0;
    }
  }
  .single-user-image {
    height: 48px;
    width: 48px;
  }
  .rooms-details {
    min-width: 145px;
    margin-bottom: 0;
    margin-right: 22px;
    margin-left: 22px;

    li {
      .long-name {
        display: none;
      }
      .short-name {
        color: $text-light;
        line-height: 14px;
        font-size: 12px;
        display: block;
      }
    }
  }
  .details-values {
    padding-bottom: 0;
    margin-right: 28px;

    & > div {
      width: auto;
      align-items: flex-start;

      &.hide-tablet {
        display: none;
      }
    }
    .value-subtitle {
      letter-spacing: -0.04px;
    }
  }
  .descriptive-block {
    align-items: center;
    flex-direction: row;
  }
  .users-bottom {
    border-top: none;
    padding-top: 0;

    .single-user {
      padding-right: 17px;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
  .details-address {
    margin-bottom: 0;

    .house-img {
      display: none;
    }
  }
  .user-info {
    .icon-info {
      font-size: 12px;
      right: -10px;
    }
  }

  .transaction-milestones {
    max-height: 260px;

    .milestone-title {
      top: 0;
    }

    .task-info {
      h5 {
        line-height: 18px;
        font-size: 14px;
      }
    }

    .task {
      padding: 12px 16px 12px 36px;

      &:before {
        height: calc(100% - 24px);
      }
      .task-icon {
        min-width: 24px;
        height: 24px;
        width: 24px;

        i {
          font-size: 12px;
        }
      }
    }
  }
}

@media (min-width: $screen-md) {
  .details-address {
    margin-left: 12px;

    &>div {
      width: 190px;
    }

    .house-img {
      display: flex;
      width: 52px;
      height: 52px;
    }

    .small-title {
      margin-bottom: 4px;
      display: inline-block;
    }
  }
  .details-values {
    display: none;
  }
  .rooms-details {
    min-width: 170px;
  }
}

@media (min-width: $screen-lg) {
  .transaction-details {
    padding: 0 24px 24px;
    min-height: 628px;
  }
  .related-users {
    margin-top: 23px;

    & > div {
      width: calc(50% - 12px);
      margin-bottom: 24px;
    }
  }
  .rooms-details {
    margin-right: 40px;
    margin-left: 40px;
    min-width: 310px;
    li {
      .short-name {
        display: none;
      }
      .long-name {
        display: block;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .details-address {
    &>div {
      width: 200px;
    }

    .small-title {
      font-size: 13px;
    }
    .house-img {
      margin-right: 16px;
      height: 60px;
      width: 60px;
    }
  }
  .transaction-milestones {
    max-height: 350px;

    .milestone-title {
      line-height: 26px;
      font-size: 16px;
    }

    .task {
      padding: 12px 20px 12px 44px;

      &:before {
        height: calc(100% - 32px);
      }
      .task-icon {
        min-width: 32px;
        height: 32px;
        width: 32px;

        i {
          font-size: 16px;
        }
      }
    }

    .task-info {
      h5 {
        line-height: 26px;
        font-size: 16px;
      }
    }
  }
  .related-users {
    .single-user {
      padding: 16px;
      &-image {
        height: 56px;
        width: 56px;
      }
    }
  }
  .users-bottom {
    .single-user {
      max-width: 170px;
    }
    & > div {
      margin-right: 48px;
    }
  }
  .single-user {
    .user-name {
      line-height: 20px;
      font-size: 16px;
    }
    .user-role {
      font-size: 13px;
    }
  }
  .single-user-image {
    margin-right: 16px;
  }
}

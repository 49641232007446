@media (min-width: $screen-sm) {
  .address-container {
    p {
      font-size: 12px;
      line-height: 15px;
    }
  }
}
@media (min-width: $screen-lg) {
  .address-container {
    div {
      font-size: 16px;
      line-height: 18px;
    }
    p {
      font-size: 13px;
      line-height: 15px;
    }
  }
}

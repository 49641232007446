.static-container {
  margin-right: auto;
  padding: 30px 20px;
  margin-left: auto;

  .titles {
    margin-bottom: 20px;
    text-align: center;

    h1 {
      margin-bottom: 10px;
    }
  }

  .section-title {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  .ordered-list {
    list-style: numeric;
    padding-left: 20px;
    margin-top: 10px;

    li {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      &::marker {
        font-family: $font-primary-bold;
      }
    }
  }

  .unordered-list {
    list-style: disc;
    padding-left: 40px;

    li {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  .link {
    color: $color-black;
    text-decoration: underline;
  }

  p {
    margin-bottom: 5px;
  }
}
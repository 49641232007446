@media (min-width: $screen-sm) {
  .static-container {
    max-width: 80%;
    p {
      font-size: 18px;
    }

   .unordered-list , .ordered-list {
     font-size: 16px;
   }
  }
}

@media (min-width: $screen-md) {
  .static-container {
    max-width: 60%;
    p {
      font-size: 18px;
    }

    h4 {
      font-size: 20px;
    }

    .unordered-list , .ordered-list {
      font-size: 18px;
    }
  }
}
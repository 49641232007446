.transaction-card {
  border-radius: $border-radius-xs;
  padding: 12px 15px;
  min-height: 62px;

  .full-name {
    min-width: 0;

    & .transaction-title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 90%;
    }
  }
}

.transaction-text {
  line-height: 22px;
}

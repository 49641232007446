@media (min-width: $screen-sm) {
  .team-content {
    padding: 32px 32px 40px;
    min-height: 312px;
    width: 424px;
  }
  .team-title {
    padding-bottom: 13px;
    margin-bottom: 24px;
    line-height: 20px;
    font-size: 18px;
  }

  .member-img {
    margin-right: 16px;
    height: 48px;
    width: 48px;
  }

  .member-details {
    max-width: 200px;
    h6 {
      line-height: 24px;
      font-size: 16px;
    }
  }

  .member-position {
    line-height: 15px;
    font-size: 12px;
  }

  .send-message-container {
    padding: 30px;
    width: 320px;

    .modal-header {
      h4 {
        padding-bottom: 14px;
      }
    }

    .modal-body {
      padding-top: 16px;
    }

    .modal-description {
      margin-bottom: 14px;
      line-height: 21px;
      font-size: 14px;
    }

    .message-field {
      height: 96px;
    }

    .modal-close {
      right: 16px;
      top: 16px;
    }
  }
}

@media (min-width: $screen-md) {
  .team-button {
    display: none;
  }
  .team-card {
    background-color: rgba($color-white, 0.9);
    padding: 24px 32px 27px;
    min-height: 278px;
  }

  .team-title {
    line-height: 24px;
    font-size: 18px;
    letter-spacing: 0.63px;
    padding-bottom: 8px;
    margin-bottom: 20px;
  }

  .member-info {
    max-width: 235px;

    .description {
      margin-right: 8px;

      h6 {
        line-height: 21px;
      }
    }
  }

  .member {
    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }

  .member-img {
    margin-right: 16px;
    height: 48px;
    width: 48px;
  }

  .member-details {
    max-width: 165px;
  }
}

@media (min-width: $screen-lg) {
  .team-card {
    padding: 38px 32px 42px;
    min-height: 372px;
  }

  .team-title {
    letter-spacing: 0.77px;
    padding-bottom: 16px;
    margin-bottom: 28px;
    line-height: 26px;
    font-size: 22px;
  }

  .member {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  .member-img {
    margin-right: 20px;
    height: 56px;
    width: 56px;
  }

  .member-details {
    h6 {
      line-height: 27px;
      font-size: 18px;
    }
  }

  .member-position {
    line-height: 15px;
    font-size: 13px;
  }

  .call-icon-container {
    height: 48px;
    width: 48px;
  }

  .send-message-container {
    padding: 23px 30px 30px;

    .message-field {
      margin-bottom: 16px;
      padding: 12px 16px;
    }

    .modal-header {
      h4 {
        letter-spacing: -0.05px;
        padding-bottom: 18px;
        line-height: 22px;
        font-size: 16px;
      }
    }
  }
}

@import '../abstracts/variables';

.important-milestones-section {
  padding: 0 10px 24px 10px;

  .card {
    background-color: white;
    box-shadow: 0 4px 16px #0000000F;
    border-radius: 4px;
    margin-top: 27px;
    padding: 0 20px 20px 20px;

    .line {
      height: 4px;
      width: 100px;
      background-color: $lightGray;
    }

    &.pending {
      .line {
        background-color: $red;
      }
    }

    &.completed {
      .line {
        background-color: $lightgreen;
      }

      .box {
        background-color: white !important;
        //content: url('../../../assets/images/check-icon.svg') !important;
      }
    }

    .title {
      padding-top: 18px;
      padding-bottom: 16px;
      font-family: $BrandonGrotesque;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.63px;
      color: $nero;
      text-transform: uppercase;
    }

    .info-section {
      display: inline-flex;
      margin-top: 20px;
      color: $nero;
      margin-right: 23px;

      .transition {
        margin-top: auto;
        margin-bottom: auto;

        &-type {
          font-family: $brown;
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 4px;
        }

        &-description {
          font-size: 11px;
          font-family: $SFProtext;
        }
      }

      .box {
        height: 40px;
        width: 40px;
        border: 1px solid #A9A9A980;
        border-radius: 4px;
        margin-right: 12px;
        display: flex;
        padding: 10px 10px;
        //content: url('../../../assets/images/hourglass.svg');
      }

      &.pending {
        cursor: pointer;

        .transition-description {
          color: $red;
        }

        .box {
          border-color: $red;
          //content: url('../../../assets/images/pencil-icon.svg');
        }
      }

      &.completed {
        .box {
          border-color: $lightgreen;
          background-color: $lightgreen;
          //content: url('../../../assets/images/white-check-icon.svg');
          color: white;
        }
      }
    }
  }

  @media screen and (min-width: 428px) {
    .card {
      padding-bottom: 24px;

      .title {
        font-size: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .info-section {
        margin-right: 30px;
        margin-top: 24px;

        .transition {
          &-description {
            font-size: 13px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    padding: 0 34px 32px 34px;
    .card {
      padding-left: 32px;
      padding-right: 32px;
      margin-top: 32px;
    }
  }
  @media screen and (min-width: 768px) {
    padding: 0 34px 32px 34px;
    .card {
      padding-left: 32px;
      padding-right: 32px;
      margin-top: 32px;
    }
  }
}


.spinner {
  z-index: 199;
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  background-color: #b5b5b58f;

  &.cover {
    background-color: #ffffff;
  }

  &.container {
    position: absolute;
    height: 100%;
  }
}

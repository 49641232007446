.transaction-section {
  background-color: $color-white;
  & .task {
    &:not(:last-child) {
      border-bottom: none;
    }
  }
}

.buyer-milestones {
  position: relative;
}

@import '../abstracts/variables';

.transaction-documents {
  .overlay-content {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  &-header {
    padding: 20px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    //.close-icon {
    //position: absolute;
    //right: 22px;
    //}
  }

  .title {
    font: normal normal bold 16px/26px $BrandonGrotesque;
    letter-spacing: 0.5px;
    color: #1C1C1C;
    text-transform: uppercase;
    //margin-left: 20px;
    margin-top: 10px;
  }

  .transition-documents-section {
    overflow: auto;
    height: calc(100vh - 76px - 166px);
  }

  .document-title {
    text-align: left;
    font: normal normal normal 14px/18px $SFProtext;
    color: #1C1C1C;
  }

  .download-icon {
    width: 20px;
    height: 18px;
    margin-right: 0;
    margin-left: auto;
    color: $lightGray;

    svg {
      color: $lightGray;
    }

    &:hover {
      svg {
        color: black;
      }
    }
  }

  .download-btn-section {
    margin-top: auto;
    padding: 20px;
    border-top: 1px solid #E8E8E8;

    .btn-2 {
      margin-bottom: 12px;
    }
  }

  @media screen and (min-width: 428px) {
    .download-btn-section {
      padding: 24px 20px;
      display: flex;
      flex-direction: row;

      .btn-1 {
        width: auto;
        padding: 20px;
        flex-basis: 50%;
      }

      .btn-2 {
        margin-right: 16px;
        width: auto;
        padding: 20px;
        flex-basis: 50%;
        margin-bottom: 0;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .overlay-content {
      width: 548px;
    }

    &-header {
      flex-direction: column;
      padding: 24px 46px 24px 46px;

      .close-icon {
        order: -1;
        display: inline-block;
        margin-right: 0;
        margin-left: auto;
      }
    }

    .title {
      font-size: 18px;
      margin-top: 0;
      order: 0;
    }

    .transition-documents-section {
      height: calc(100vh - 90px - 106px);
    }

    .checkbox-label-container {
      padding: 25px 46px 25px 46px;

      .checkbox-container {
        font-size: 14px;
      }
    }

    .download-btn-section {
      padding: 24px 46px;
    }
  }
}
@media (min-width: $screen-sm) {
  header {
    .inner {
      padding: 20px 0;
    }

    .header-logo {
      max-width: 205px;
      height: 36px;
    }

    .data-text {
      display: block;
    }
  }
}

@media (min-width: $screen-md) {
  header {
    padding: 0 32px;

    .inner {
      padding: 22px 0;
    }

    .header-back-btn {
      cursor: pointer;
      display: block;
      padding: 0;
      font-size: 14px;
      line-height: 14px;
      color: $color-primary;

      &.disabled{
        cursor: default;
        pointer-events: none;
      }
    }
  }
}

@media (min-width: $screen-lg) {
  header {
    padding: 0 34px;

    .inner {
      padding: 30px 0;
    }

    .header-back-btn {
      font-size: 15px;
      line-height: 15px;
    }
  }
}

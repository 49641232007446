.user-right-menu {
  position: relative;
  padding-top: 25px;

  .close-btn {
    background-color: transparent;
    transition: $transition-xs;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    position: absolute;
    cursor: pointer;
    display: flex;
    height: 40px;
    right: 10px;
    width: 40px;
    top: 10px;

    i {
      color: $color-black;
      font-size: 12px;
    }

    &:hover {
      background-color: rgba($color-black, 0.06);
    }
  }

  .user-main-info {
    border-bottom: 1px solid $color-border;
    justify-content: center;
    flex-direction: column;
    padding: 0 24px 15px;
    align-items: center;
    display: flex;
  }

  .user-main-info-dropdown {
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 16px;
    min-width: 176px;
    height: 32px;
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding: 0 15px;

    &-value {
      letter-spacing: 0px;
      font-family: $font-secondary;
      font-size: 13px;
      line-height: 20px;
      color: #1c1c1c;

      span {
        letter-spacing: 0px;
        color: #666666;
      }

      & + i {
        top: 7px;
      }
    }
  }

  .user-img {
    margin-bottom: 10px;
    position: relative;
    height: 80px;
    width: 80px;
  }

  .edit-btn {
    background-color: $color-white;
    box-shadow: $shadow-md-x;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    display: flex;
    height: 32px;
    width: 32px;
    bottom: 0;
    right: 0;

    input {
      display: none;
      visibility: hidden;
      opacity: 0;
    }

    i {
      transition: $transition-xs;
      color: $color-gray;
      font-size: 16px;
    }

    &:hover {
      & i {
        color: $color-black;
      }
    }
  }

  .user-firstname {
    text-overflow: ellipsis;
    letter-spacing: 0.07px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
  }

  .user-role {
    font-size: 11px;
    line-height: 13px;
    font-family: $font-secondary;
  }

  .user-img {
    .placeholder {
      font-size: 24px;
      line-height: 20px;
    }
  }
}

.user-form {
  border-bottom: 1px solid $color-border;
  padding: 35px 24px 20px;
  position: relative;

  .checkbox-container.disabled {
    opacity: 0.5;
  }
}

.input-group {
  flex-direction: column;
  display: flex;

  &.with-error {
    input {
      border-bottom-color: $color-black;
      color: $color-black;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  label {
    font-family: $font-secondary;
    color: $color-gray40;
    line-height: 13px;
    font-size: 11px;
  }

  .input-field {
    font-family: $font-secondary;
    border-bottom-color: transparent;
    background-color: transparent;
    border-bottom-style: solid;
    transition: $transition-xs;
    border-bottom-width: 1px;
    text-overflow: ellipsis;
    padding: 8px 0 7px;
    word-break: break-all;
    pointer-events: none;
    line-height: 17px;
    font-size: 14px;

    &.edit-mode:not(.email) {
      border-bottom-color: $color-border;
      pointer-events: auto;

      &:hover {
        border-bottom-color: $color-black;
      }
    }
  }

  .error-msg {
    font-family: $font-secondary;
    color: $color-error;
    margin-bottom: 8px;
    line-height: 10px;
    margin-top: 10px;
    font-size: 10px;
  }
}

.user-form-password {
  border-bottom: 1px solid $color-border;
  position: relative;
  padding: 35px 24px 22px;

  .input-group {
    &:not(:only-of-type) {
      margin-bottom: 22px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.save-btn {
  &.sm {
    font-family: $font-primary;
    line-height: 17px;
    margin-top: 20px;
    font-size: 14px;
  }
}

.user-form-edit-btn {
  border-color: $color-border;
  transition: $transition-xs;
  justify-content: center;
  border-style: solid;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  border-width: 1px;
  cursor: pointer;
  display: flex;
  height: 32px;
  right: 24px;
  width: 32px;
  top: 10px;

  i {
    font-size: 12px;
    color: $color-gray;

    &.icon-close {
      font-size: 10px;
    }
  }

  &:hover {
    border-color: $color-black;

    i {
      color: $color-black;
    }
  }
}

.user-right-log-out {
  padding: 20px 24px;

  .btn {
    font-family: $font-secondary;
    line-height: 17px;
    font-size: 14px;
    height: 48px;
  }
}

.user-main-info-dropdown-options {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 16px 24px #0f0f0f1f;
  border-radius: 4px;
  opacity: 1;
  width: 176px;

  & > div {
    padding: 0 0 0 8px;
    height: 32px;
  }

  .user-main-info-dropdown-option {
    font-size: 14px;
    line-height: 18px;
    font-family: $font-primary;
    letter-spacing: 0px;
    color: #1c1c1c;

    & + i {
      top: 12px;
    }
  }
}

//margin
@for $i from 0 through 60 {
  .mt-#{$i} {
    margin-top: #{$i}px;
  }
  .mb-#{$i} {
    margin-bottom: #{$i}px;
  }
  .mr-#{$i} {
    margin-right: #{$i}px;
  }
  .ml-#{$i} {
    margin-left: #{$i}px;
  }
  .m-#{$i} {
    margin:  #{$i}px;
  }
}

//padding
@for $i from 0 through 60 {
  .pt-#{$i} {
    padding-top: #{$i}px;
  }
  .pb-#{$i} {
    padding-bottom: #{$i}px;
  }
  .pr-#{$i} {
    padding-right: #{$i}px;
  }
  .pl-#{$i} {
    padding-left: #{$i}px;
  }
  .p-#{$i} {
    padding:  #{$i}px;
  }
}
header {
  box-shadow: 0 -1px 3px $bg-light;
  background-color: $bg-light;
  align-items: center;
  position: sticky;
  flex-shrink: 0;
  display: flex;
  z-index: 20;
  top: 0;
  padding: 0 20px;

  .data-text {
    display: none;
  }

  .inner {
    justify-content: space-between;
    align-items: center;
    display: flex;
    width: 100%;
    padding: 16px 0;
  }

  .header-logo {
    display: inline-block;
    max-width: 165px;
    height: 30px;
    width: 100%;
  }

  .header-back-btn {
    display: none;
  }
}

.avatar-img {
  object-fit: cover;
  display: block;
  height: 100%;
  width: 100%;

  &.placeholder {
    background-color: $color-gray99;
    border: 1px solid $color-border;
    justify-content: center;
    align-items: center;
    color: $text-light;
    line-height: 20px;
    font-size: 18px;
    flex-shrink: 0;
    display: flex;

    &.darken {
      background-color: $color-grayE7;
      border-color: $color-border-darken;
    }
  }

  &.rounded {
    border-radius: 50%;
    overflow: hidden;
  }

  &.loading {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    background: black;
    opacity: 0.3;
  }
}

.right-side {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  bottom: 20px;
  right: 20px;
  z-index: 18;

  .due-date-title {
    text-transform: uppercase;
    font-family: $font-tertiary;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .due-date-description {
    font-family: $font-secondary;
    color: $text-light;
    font-size: 13px;
    line-height: 21px
  }

  .right-side-back-btn {
    cursor: pointer;
    width: 64px;
    height: 64px;
    padding: 20px;
    margin-bottom: 12px;
    background: $color-white;
    color: #000000;
    border-radius: 50%;
    box-shadow: 0px 4px 15px #22232626;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    font-size: 16px;
  }
}

.data-img {
  border-radius: 50%;
  margin-left: 16px;
  overflow: hidden;
  height: 36px;
  width: 36px;
}

.data-role {
  font-family: $font-secondary;
  line-height: 14px;
  text-align: right;
  font-size: 11px;
}

.escrow-card {
  height: 288px;
}

.wrapper-background {
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  z-index: 0;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.45);
  }
}

.transactions-wrapper {
  display: flex;

  .transactions-left-side {
    height: 100vh;
    background-color: $bg-light;
    flex: 1;
    display: flex;
    flex-direction: column;

    .main-component {
      padding: 25px 0;
      margin: 0 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      flex: 1 auto;
      border-top: 1px solid #ececec;
      height: calc(100vh - 70px);

      & > h4 {
        line-height: 28px;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: -0.36px;
      }

      .transactions-cards-overflow {
        height: 100%;
        overflow-y: auto;

        .transactions-cards {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 16px;
        }
      }
    }
  }

  .transactions-right-side {
    width: 0;
  }
}

@media (min-width: $screen-sm) {
  .transaction-card {
    padding: 17px 20px;
  }
  .transaction-title {
    font-family: $font-primary-bold;
    line-height: 20px;
    font-size: 14px;
  }
  .transaction-text {
    font-size: 14px;
    line-height: 20px;
  }
  .commission-value {
    margin-bottom: 2px;
    line-height: 10px;
    font-size: 12px;
  }
  .small-text {
    color: $text-light;
    line-height: 10px;
    font-size: 8px;
  }
  .transaction-subtitle {
    color: $text-light;
    line-height: 15px;
    font-size: 12px;
  }
}

@media (min-width: $screen-md) {

}

@media (min-width: $screen-lg) {
  .transaction-card {
    padding: 13px 24px 19px;
  }
  .transaction-title {
    line-height: 22px;
    font-size: 16px;
  }
  .transaction-text {
    font-size: 16px;
    line-height: 20px;
  }
  .commission-value {
    line-height: 12px;
    font-size: 13px;
  }
  .small-text {
    line-height: 12px;
    font-size: 9px;
  }
  .transaction-subtitle {
    line-height: 18px;
    font-size: 13px;
  }
}
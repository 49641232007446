@media (min-width: $screen-sm) {
  .empty-data-message {
    padding-bottom: 43px;
    margin-right: 20px;
    margin-left: 20px;
    padding-top: 40px;

    .title {
      margin-bottom: 4px;
      line-height: 20px;
      max-width: 100%;
      font-size: 20px;
    }

    .description {
      line-height: 22px;
      max-width: 100%;
      font-size: 14px;
    }
  }
}

@media (min-width: $screen-md) {
  .empty-data-message {
    margin-right: 32px;
    margin-left: 32px;

    .title {
      line-height: 24px;
      font-size: 18px;
    }
  }
}

@media (min-width: $screen-lg) {
  .empty-data-message {
    margin-right: 34px;
    margin-left: 34px;

    .title {
      line-height: 26px;
      font-size: 22px;
    }

    .description {
      line-height: 26px;
      font-size: 16px;
    }
  }
}

.form-wrapper {
  height: 100%;
  width: 100%;
  font-family: Brown;
  z-index: 100;

  .loader {
    & + .form-wrapper-inner {
      opacity: 0.3;
    }
  }
}

.form-wrapper-inner {
  height: 100%;
  width: 100%;
  background-image: url("../../../images/bg-img-hq-size-Q3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.form-container {
  max-width: 704px;
  width: 100%;
  min-height: 100%;
  padding: 48px 126px;
  background-color: rgba(#FFFFFF, 0.97);
  transition: opacity 120ms;
}

.form-logo {
  display: block;
  object-fit: contain;
  object-position: left;
  image-rendering: -webkit-optimize-contrast;
  cursor: pointer;
  max-width: 165px;
  height: 32px;

  @media (min-width: 758px) {
    height: 40px;
    max-width: 206px;
  }
}

.form-hr {
  width: 44px;
  height: 2px;
  border: none;
  background-color: #6DC480;
  margin: 180px 0 55px 0;
}

.password-header {
  font-size: 42px;
  line-height: 46px;
  margin-bottom: 56px;
  color: #1E1E1E;

  &.forgot {
    font-size: 40px;
    line-height: 44px;
    margin-bottom: 32px;
  }
}

.form-login-cnt {
  width: 100%;
}

.form-login {
  width: 100%;
  height: 65px;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &:last-child {
    margin-bottom: 30px;
  }
}

.form-input {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 6px);
  border-bottom: 1px solid #1E1E1E;
  outline: none;
  background: none;
  font-size: 16px;
  padding: 0 30px 0 0;
  z-index: 1;
  color: #1E1E1E;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 65px 0 0 #ffffff inset !important;
  }

  &::placeholder {
    color: #1E1E1E;
  }

  &.active ~ .form-label {
    top: -10px;
    left: 0;
    transform: scale(0.7);
  }

  &.borderActive {
    border-bottom: 1px solid #6DC480;
  }

  &.borderError {
    border-bottom: 1px solid #FF0000;
  }

  &.disabled {
    pointer-events: none;
    border-bottom-color: #5C5C5C99;
    cursor: auto;
  }
}

.form-label {
  display: block;
  position: absolute;
  top: 35%;
  left: 0;
  font-size: 15px;
  line-height: 19px;
  color: #1E1E1E;
  transition: top 240ms ease-in-out, left 240ms ease-in-out, transform 240ms ease-in-out;
  letter-spacing: 0.15px;
  pointer-events: none;
  transform-origin: left;

  &.disabled {
    color: #5C5C5C99;
  }
}

.form-input:focus ~ .form-label {
  top: -10px;
  left: 0;
  transform: scale(0.7);
}

.form-input-icon {
  display: block;
  position: absolute;
  font-size: 21px;
  line-height: 21px;
  height: 21px;
  color: #707070;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto 0;
  cursor: pointer;
  z-index: 10;
}

.input-massage-icon {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: contain;
}

.error {
  background-image: url("../../../images/error.png");
}

.errorText {
  font-size: 14px;
  line-height: 18px;
  color: #F80000;
  float: left;
  position: relative;
  top: -26px;
}

.password-match {
  font-size: 14px;
  line-height: 18px;
  color: #6DC480;
  float: left;
  position: relative;
  top: -22px;
}

.caps-lock {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 19px;
  margin: auto 0;
  color: #1E1E1E;
  font-size: 15px;
  line-height: 19px;
}


.input-valid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  grid-gap: 11px;
  margin-bottom: 38px;
  position: relative;
  top: -10px;
}

.check-column {
  display: grid;
  grid-gap: 9px;
  grid-template-columns: 12px 11fr;
  align-items: center;
}

.valid {
  color: #1E1E1E;
  font-size: 12px;
  line-height: 16px;
}

.check {
  height: 8px;
  width: 8px;
  background-color: $lightGray;
  border-radius: 50%;
}

.form-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.check-valid {
  width: 12px;
  height: 12px;
  position: relative;
  left: -2px;
  object-fit: cover;
}

.notValid {
  color: #666666;
  font-size: 12px;
  line-height: 16px;
}

.confirm-password {
  color: #5C5C5C99;
}

.borderDisabled {
  border-bottom: 1px solid #5C5C5C99;
}

.form-bottom-text {
  color: #666666;
  font-size: 14px;
  line-height: 18px;
  padding-top: 32px;

  > a {
    display: inline;
    color: #282828;
  }
}

//.activeButton {
//  margin-bottom: 20rem;
//}

.password-createText {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 32px;
}

.forms-button {
  width: 100%;
  height: 54px;
  pointer-events: none;
  background-color: #EDEDED;
  color: #5C5C5C;
  font-size: 15px;
  line-height: 19px;
  padding: 0 20px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: bold;
}

.login-input {
  border-bottom: 1px solid $lightGray;
}

.forgot-input {
  border-bottom: 1px solid $lightGray;
}

.rememberMeAndSubmitSection {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 18px;
  
  .checkbox-div {
    padding-bottom: 48px;
  }
}

.remember-checkBox {
  display: flex;
}

.remember-checkBox label {
  font-size: 15px;
  line-height: 20px;
}

.rememberMeAndSubmitSection a {
  color: #1E1E1E;
  font-size: 15px;
  line-height: 20px;
}

.send-button {
  background: #EDEDED;
  color: #5C5C5C;
}

.checkbox-div {
  border: none;
  padding: 0;

  .document-title {
    font-size: 15px;
    line-height: 20px;
    color: #1E1E1E;
  }
}

.checkmark-remember {
  margin-right: 8px;
}

.check-remember {
  input:checked ~ .checkmark-remember {
    border-color: $lightGray;
  }

  .checkmark-remember:after {
    background: #6DC480;
  }
}

.loginPage-checkedImg {
  position: absolute;
  right: 0;
}

.create-password-link {
  margin-top: 48px;
  padding: 25px 0;
  border-top: 1px solid #DDDDDD;
  color: #282828;
  text-decoration: underline;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.23px;
  color: #1e1e1e;
}

@media screen and (max-width: 1610px) {
  .form-container {
    max-width: 632px;
    padding: 48px 96px;
  }
  .form-hr {
    margin: 133px 0 55px 0;
  }

  .create-password-link {
    margin-top: 40px;
    padding: 20px 0;
  }
}

@media screen and (max-width: 1240px) {
  .form-container {
    max-width: 374px;
    padding: 48px;
  }
  .password-header {
    font-size: 27px;
    line-height: 31px;
  }
  .rememberMeAndSubmitSection {
    flex-direction: column;

    a.checkbox-div  {
      order: 4;
      padding-top: 32px;
      padding-bottom: 0;
    }
  }
  .input-valid {
    grid-template-columns: 12fr;
    grid-gap: 8px;
  }
  .check-column {
    &:nth-child(3) {
      grid-row-start: 2;
    }
    &:nth-child(5) {
      grid-row-start: 3;
    }
    &:nth-child(2) {
      grid-row-start: 4;
    }
  }

  .create-password-link {
    margin-top: 40px;
    padding: 18px 0;
  }
}

@media screen and (max-width: 738px) {
  .form-wrapper {
    background-image: none;
    overflow: unset;
  }
  .form-container {
    width: 100%;
    max-width: 100%;
    padding: 20px;
    background-color: #ffffff;
  }

  .form-hr {
    margin: 38px 0 28px 0;
  }
  .password-header {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 53px;
  }
}

.terms-and-privacy {
  padding-top: 32px;
  line-height: 24px;
  max-width: 250px;
  color: #666666;

  a {
    color: #282828;
    text-decoration: underline;
  }

  @media (min-width: 1430px) {
    max-width: 100%;
  }
}
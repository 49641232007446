html,
#root,
.App,
#wrapper,
.wrapper-background {
  height: 100%;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

html {
  -webkit-tap-highlight-color: transparent;
}

input:autofill,
input:-webkit-autofill {
  background: transparent;
}

body {
  line-height: $line-height-base;
  font-size: $font-size-base;
  font-family: $font-primary;
  background: $color-white;
  color: $color-primary;
  padding: 0;
  margin: 0;

  &[data-scroll="disable_scroll"] {
    overflow: hidden !important;

    .overlay-content,
    .flyout-content,
    .transactions-cards-overflow,
    .form-wrapper-inner,
    .scrollableY,
    .main {
      overflow: hidden !important;
    }
  }
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding: 0 16px;
  width: 100%;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-grow {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.scrollableY {
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.main {
  background-color: $bg-light;
  height: 100%;
  margin-right: 0;
  margin-left: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.relative {
  position: relative;
}

.divider {
  border-top: 1px solid $color-border;
}

//delete after modal refactoring
.modal-close-btn {
  font-size: 24px;
  color: $color-gray-light;
  position: absolute;
  cursor: pointer;
  transition: $transition-xs;
  right: 0;
  top: 0;

  &:hover {
    color: $text-light;
  }
}

.hide-desktop {
  display: block;
}

.hide-tablet {
  display: block;
}

.hide-mobile {
  display: none;
}

.show-desktop-lg {
  display: none;
}
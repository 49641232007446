//font-families
$font-primary: "Brown Regular", sans-serif;
$font-primary-bold: "Brown Bold", sans-serif;
$font-secondary: "SFProText Regular", sans-serif;
$font-secondary-bold: "SFProText Bold", sans-serif;
$font-tertiary: "BrandonGrotesque Bold", sans-serif;

//colors
$color-primary: #1c1c1c;
$color-secondary: #1e1e1e;
$color-white: #ffffff;
$color-gray: #aaaaaa;
$color-gray99: #fcfcfc;
$color-grayE7: #e7e7e7;
$color-gray40: #666666;
$color-gray-light: #C4C4C4;
$color-gray-dark: #707070;
$color-gray-medium: #D2D2D2;
$color-border: #e8e8e8;
$color-border-darken: #d9d9d9;
$color-black: #000000;
$color-success: #6dc480;
$bg-light: #fbfbfb;
$bg-hover: #fafafa;
$color-step: #ebebeb;
$color-step-filled: #6ec37f;
$bg-disabled: #eee;
$color-disabled: #5C5C5C;
$color-error: #F80000;

//text colors
$text-primary: #1c1c1c;
$text-secondary: #1e1e1e;
$text-light: #6a6a6a;

//font sizes
$font-size-base: 14px;
$line-height-base: 22px;

//form colors
$input-color: #000000;
$input-border-color: #e8e8e8;

//transitions
$transition-xs: all 0.2s ease-in-out;
$transition-sm: all 0.4s ease-in-out;

//screens
$screen-xs: 365px;
$screen-sm: 758px;
$screen-md: 1430px;
$screen-lg: 1910px;

//border radius
$border-radius-xs: 4px;
$border-radius-sm: 16px;

//shadow
$shadow-xs: 0 1px 3px #2828280f; // 0.06%
$shadow-sm: -12px 6px 30px #28282833; // 0.2%
$shadow-sm-x: 0 6px 30px #28282833; // 0.2%
$shadow-md : 0 4px 16px #0000000d; //0.05%
$shadow-md-x : 0 4px 8px #00000029; //0.16%
$shadow-lg: 0 12px 48px #28282833; //0.2%

.transactions {
  display: flex;
  flex-grow: 1;
  height: 100%;
  flex-direction: column;
}

.transaction-details {
  padding: 0 16px 16px;
  position: relative;
  min-height: 700px;

  .milestones-section {
    .container {
      padding: 0 12px;
    }
  }
}

.related-users {
  flex-direction: column;
  margin-bottom: 12px;
  margin-top: 12px;
  display: flex;

  & > div {
    margin-bottom: 8px;
  }

  .single-user {
    border-radius: $border-radius-xs;
    border: 1px solid $color-border;
    padding: 12px;

    & > div {
      max-width: 95%;
    }
  }
}

.single-user {
  & > div {
    align-items: center;
    display: flex;
  }

  &-image {
    border-radius: $border-radius-xs;
    margin-right: 12px;
    height: 40px;
    width: 40px;
  }

  .user-role {
    color: $text-light;
    line-height: 15px;
    font-size: 12px;
  }

  .user-name {
    font-family: $font-primary-bold;
    text-decoration: underline;
    word-break: break-all;
    line-height: 18px;
    cursor: pointer;
    font-size: 14px;
  }
}

.user-info {
  flex-direction: column;
  display: flex;
  position: relative;
  align-items: start;

  .icon-info {
    transform: translate(50%, -50%);
    color: $color-gray-medium;
    position: absolute;
    font-size: 8px;
    right: -7px;
    top: 5px;
  }
}

.details-address {
  margin-bottom: 20px;
  align-items: center;
  flex-shrink: 0;
  display: flex;

  .house-img {
    border-radius: $border-radius-xs;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    height: 48px;
    width: 48px;

    i {
      font-size: 38px;
    }
  }

  .small-title {
    color: $text-light;
    line-height: 15px;
    font-size: 12px;
  }
}

.rooms-details {
  border-radius: $border-radius-xs;
  border: 1px solid $color-border;
  justify-content: space-between;
  padding-bottom: 12px;
  margin-bottom: 13px;
  padding-top: 8px;
  display: flex;

  li {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    display: flex;
    width: 33.3%;

    .long-name {
      color: $text-light;
      line-height: 15px;
      font-size: 12px;
    }

    .short-name {
      display: none;
    }

    .rooms-count {
      line-height: 18px;
    }

    &:not(:last-child):before {
      content: "";
      background-color: $color-border;
      transform: translateY(-50%);
      position: absolute;
      height: 100%;
      width: 1px;
      right: 0;
      top: 50%;
    }
  }
}

.descriptive-block {
  display: flex;
  flex-direction: column;
}

.details-values {
  padding-bottom: 15px;
  display: flex;

  & > div {
    flex-direction: column;
    align-items: center;
    display: flex;
    width: 33.3%;
  }

  .value-subtitle {
    white-space: nowrap;
    color: $text-light;
    line-height: 15px;
    font-size: 12px;
  }

  .value-title {
    font-family: $font-primary-bold;
    white-space: nowrap;
    line-height: 18px;
    font-size: 14px;
  }

  .value-regular-title {
    white-space: nowrap;
    line-height: 18px;
  }
}

.users-bottom {
  border-top: 1px solid $color-border;
  padding-top: 16px;
  display: flex;
  gap: 12px;

  .single-user {
    padding-right: 12px;
    max-width: 150px;
    &:first-child {
      margin-right: 18px;
    }
  }
}

.transaction-milestones {
  border-radius: $border-radius-xs;
  border: 1px solid $color-border;
  background-color: $bg-light;
  position: relative;
  max-height: 320px;
  margin-top: 20px;
  min-height: 50px;

  .milestones-section {
    background-color: $bg-light;
  }

  .milestone-title {
    background-color: $bg-light;
    padding-bottom: 12px;
    padding-top: 16px;
  }

  .task {
    padding: 14px 16px 14px 40px;

    .task-icon {
      i {
        font-size: 16px;
      }
    }
  }
}

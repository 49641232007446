@media (min-width: $screen-sm) {
  .btn {
    //button sizes
    &.xs {
      line-height: 24px;
      font-size: 12px;
      height: 48px;
    }
  }
}

@media (min-width: $screen-md) {
  .btn {
    //button sizes
    &.md {
      line-height: 16px;
      font-size: 14px;
      height: 48px;
    }
  }
}

@media (min-width: $screen-lg) {
  .btn {
    //button sizes
    &.sm {
      line-height: 24px;
      font-size: 12px;
      height: 48px;
    }

    &.md {
      line-height: 24px;
      font-size: 16px;
      height: 56px;
    }
  }
}

.dropdown-container {
  position: relative;
  display: inline-block;

  &-name {
    position: relative;
    padding-right: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: 100%;

    &.disabled {
      pointer-events: none;
      cursor: default;
    }

    i {
      position: absolute;
      right: 0;
      top: 8px;
      font-size: 8px;
      height: fit-content;
      transition: transform 0.3s linear;

      &.open {
        transform: rotate(180deg);
      }
    }
  }
}

.dropdown-content {
  display: none;
  position: fixed;
  background-color: #f9f9f9;
  min-width: 160px;
  max-width: 360px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 8px;
  z-index: 100;
  border-radius: 4px;

  &.open {
    display: block;
  }

  &-option {
    padding: 8px 50px 8px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    height: 56px;
    width: 100%;

    .selected-icon {
      display: none;

      &-active {
        display: block;
        position: absolute;
        top: 22px;
        right: 12px;
        color: $lightgreen;
      }
    }
  }
}

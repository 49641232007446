.collapse {
  margin: 0;
  padding: 0;
  border-bottom: 0;

  .collapse-item {
    margin-bottom: 8px;
    background-color: $color-white;
    box-shadow: $shadow-xs;
    border-radius: $border-radius-xs;

    .collapse-header {
      cursor: pointer;
    }

    .collapse-content {
      transition: max-height 0s, opacity 0s;
      max-height: 0;
      opacity: 0;
      overflow: hidden;
    }

    &.active .collapse-content {
      transition: max-height 0.2s ease-in-out, opacity 0.3s linear;
      opacity: 1;
      max-height: 2000px;
    }
  }
}

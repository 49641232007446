.team-button {
  width: 64px;
  height: 64px;
  padding: 20px;
  background: #2b2b2b;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  cursor: pointer;
  color: $color-white;
  font-size: 16px;
  box-shadow: 0px 4px 15px #22232626;
  
  i.icon-users {
    font-size: 21px;
  }
}

.team-content {
  border-radius: $border-radius-sm;
  background-color: $color-white;
  box-shadow: $shadow-sm-x;
  padding: 20px 24px 24px;
  min-height: 250px;
  position: fixed;
  display: none;
  bottom: 104px;
  width: 343px;
  right: 20px;
  z-index: 5;

  &.active {
    display: block;
  }
}

.team-title {
  border-bottom: 1px solid $color-gray-medium;
  font-family: $font-tertiary;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  padding-bottom: 12px;
  margin-bottom: 16px;
  line-height: 20px;
  font-size: 16px;
}

.member {
  justify-content: space-between;
  align-items: center;
  display: flex;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.member-position {
  font-family: $font-secondary;
  display: inline-block;
  color: $text-light;
  line-height: 13px;
  font-size: 11px;
}

.member-info {
  align-items: center;
  display: flex;
  width: 100%;
}

.member-details {
  max-width: 150px;

  h6 {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.member-img {
  border-radius: $border-radius-xs;
  margin-right: 12px;
  flex-shrink: 0;
  height: 40px;
  width: 40px;
}

.contact-icons {
  align-items: center;
  display: flex;
}

.call-icon-container {
  border: 1px solid rgba(#636363, 0.4);
  justify-content: center;
  border-radius: 100%;
  align-items: center;
  margin-right: 24px;
  cursor: pointer;
  display: flex;
  height: 40px;
  width: 40px;

  &:hover {
    background-color: $color-success;
    border-color: $color-success;

    i {
      color: $color-white;
    }
  }

  i {
    color: $color-success;
    font-size: 16px;
    transition: all 0.2s ease-in-out;
  }
}

.message-icon-container {
  i {
    transition: $transition-xs;
    cursor: pointer;
    font-size: 16px;
    color: #636363;

    &:hover {
      color: $color-secondary;
    }
  }
}

//to do Lilit delete important
.send-message-container {
  transform: translate(-50%, -50%) !important;
  border-radius: $border-radius-sm;
  background-color: $color-white;
  box-shadow: $shadow-lg;
  position: relative;
  padding: 16px 20px;
  min-height: 195px;
  width: 270px;
  left: 50%;
  top: 50%;

  .modal-header {
    h4 {
      border-bottom: 1px solid $color-gray-medium;
      padding-bottom: 10px;
      letter-spacing: -0.04px;
    }
  }

  .modal-close {
    position: absolute;
    right: 8px;
    top: 8px;
    i {
      color: $color-gray-light;
      font-size: 24px;
    }
  }

  .modal-body {
    padding-top: 10px;
  }

  .modal-description {
    letter-spacing: -0.04px;
    margin-bottom: 10px;
    color: $text-light;
    line-height: 14px;
    font-size: 12px;
  }

  .message-field {
    border: 1px solid $color-gray-medium;
    color: $color-gray40;
    margin-bottom: 10px;
    padding: 10px 12px;
    line-height: 19px;
    font-size: 13px;
    height: 55px;
    resize: none;
    width: 100%;
  }
}

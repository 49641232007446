.task {
  border: 1px solid $color-border;
  padding: 14px 16px 14px 40px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  display: flex;

  &:before {
    content: "";
    transform: translateY(-50%);
    position: absolute;
    height: calc(100% - 32px);
    width: 4px;
    left: 16px;
    top: 50%;
  }

  .sent {
    display: none;
  }

  &.fullfilled {
    &:before {
      background-color: $color-success;
    }

    .task-icon {
      // background-color: $color-success;
      // color: $color-white;
      border: 1px solid $color-success;
      color: $color-success;

      i {
        &:before {
          content: "\e901";
        }
      }
    }

    &.hideIfFullfilled {
      display: none;
    }
  }

  &.completed {
    &:before {
      background-color: $color-success;
    }

    .task-icon {
      border: 1px solid $color-success;
      color: $color-success;

      i {
        &:before {
          content: "\e901";
        }
      }
    }
  }

  &.pending,
  &.in-progress {
    &:before {
      background-color: $color-gray;
    }

    .task-icon {
      border: 1px solid $color-gray;
      color: $color-gray-dark;
    }
  }

  &.pending {
    .sent {
      display: inline;
    }

    .task-icon {
      i {
        &:before {
          content: "\e90f";
        }
      }
    }
  }

  &.in-progress {
    .task-icon {
      i {
        &:before {
          // content: "\e90c";
          content: "\e90f";
        }
      }
    }
  }

  .task-info {
    justify-content: space-between;
    flex-direction: column;
    padding-right: 20px;
    display: flex;
    flex-grow: 1;

    h5 {
      position: relative;
      margin-top: -2px;
      padding-right: 18px;
      width: fit-content;

      i {
        cursor: pointer;
        position: absolute;
        color: #D2D2D2;
        font-size: 12px;
        margin: 0;
        top: 0px;
        right: 0;

        &.icon-visibility-hide {
          font-size: 14px;
        }
      }
    }
  }

  .task-icon {
    border-radius: $border-radius-xs;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 34px;
    font-size: 16px;
    min-width: 36px;
    display: flex;
    height: 36px;
    width: 36px;
  }

  .transaction-date {
    font-family: $font-secondary;
    line-height: 13px;
    font-size: 11px;
    min-width: 80px;
  }
}

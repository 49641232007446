$brown: 'Brown';
$SFProtext: 'SF UI Text';

.contactModalContainer {
  position: absolute;
  left: 28rem;
}

.contactModalBackground {
  width: 20rem;
  background: #FFFFFF;
  box-shadow: 0 12px 48px #00000052;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
}

.clientInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clientInfo .contactModalImg {
  display: block;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  margin: 36px 0 15px 0;
  object-fit: cover;
}

.contactModalName {
  color: #1C1C1C;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
}

.contactModalTitle {
  color: #6A6A6A;
  font-size: 12px;
  line-height: 20px;
}

.contactModalBodyInfo {
  display: flex;
  margin-top: 22px;
  padding: 0 34px;
  border-bottom: 1px solid $lightGray;
}

.contactModalBodyInfo p {
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 10px;
  cursor: pointer;
  color: #6A6A6A;

  &.active {
    color: #1C1C1C;
    border-bottom: 2px solid #000000;
  }

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.contactModalPhone {
  display: flex;
  align-items: center;
  margin-top: 22px;
  padding: 0 34px;
}

.contactModalMessage {
  display: flex;
  align-items: center;
  margin-top: 19px;
  margin-bottom: 23px;
  padding: 0 34px;
}

.contactModalPhone p {
  font-size: 15px;
  line-height: 20px;
}

.contactModalMessage p {
  font-size: 15px;
  line-height: 20px;
}

.contactModalPhone svg ,
.contactModalMessage svg
{
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.contactModalSeeDashboard {
  padding: 0 34px 33px 34px;
}

.contactModalSeeDashboard button {
  height: 40px;
  background: #6DC480;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 13px;
  line-height: 17px;
  padding: 0 10px;
}

.contactModalInputMessage {
  margin: 18px 0 12px 0;
  padding: 0 34px;
}

.contactModalInputMessage textarea {
  border: 1px solid #D2D2D2;
  border-radius: 8px;
  font-size: 15px;
  line-height: 19px;
  max-width: 100%;
  min-width: 100%;
  max-height: 90px;
  min-height: 72px;
  padding: 12px 16px;
  resize: none;

  &.active {
    border: 1px solid #6DC480;
  }
}

.contactModalInputMessage textarea:focus {
  outline: none;
}

.contactModalSend {
  padding: 0 34px 34px 34px;
}

.contactModalSend button {
  height: 40px;
  background: #EDEDEDFD;
  border-radius: 8px;
  font-size: 13px;
  line-height: 17px;
  padding: 0 10px;

  &.active {
    background: #6DC480;
    color: #FFFFFF;
  }
}

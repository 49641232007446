@media (min-width: $screen-sm) {
  .unauthorized-container {
    h4 {
      margin-top: 20px;
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.63px;
    }

    p,
    a {
      font-size: 14px;
    }
  }
}

@media (min-width: $screen-md) {
  .unauthorized-container {
    h4 {
      margin-top: 24px;
      margin-bottom: 12px;
    }

    p,
    a {
      font-size: 16px;
      line-height: 26px;
    }
  }
}

@media (min-width: $screen-lg) {
  .unauthorized-container {
    h4 {
      font-size: 22px;
      line-height: 26px;
      letter-spacing: 0.77px;
      margin-bottom: 16px;
    }
  }
}

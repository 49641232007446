.hide-completed {
  justify-content: flex-end;
  align-items: center;
  line-height: 13px;
  position: absolute;
  font-size: 10px;
  display: flex;
  right: 12px;
  z-index: 5;
  top: 16px;

  .button-section {
    background-color: $color-black;
    border-radius: 18px;
    position: relative;
    margin-left: 6px;
    cursor: pointer;
    height: 18px;
    width: 32px;

    &.active {
      background-color: $color-success;

      .button {
        left: calc(100% - 2px);
        transform: translate(-100%, -50%);
      }
    }

    .button {
      background-color: $color-white;
      transform: translate(0,-50%);
      transition: $transition-sm;
      pointer-events: none;
      border-radius: 50%;
      position: absolute;
      height: 14px;
      width: 14px;
      left: 2px;
      top: 50%;

      &.disableAnimation{
        transition: none;
      }
    }
  }
}

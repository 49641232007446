@media (min-width: $screen-sm) {
  .transactions-wrapper {
    .transactions-left-side {
      .main-component {
        padding-top: 35px;
        margin: 0 20px;
        gap: 20px;
        height: calc(100vh - 84px);

        & > h4 {
          line-height: 36px;
          font-size: 32px;
          letter-spacing: -0.48px;
        }

        .transactions-cards-overflow {
          .transactions-cards {
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-md) {
  .transactions-wrapper {
    .transactions-left-side {
      margin-left: 64px;

      .main-component {
        margin: 0 32px;
        gap: 24px;

        & > h4 {
          line-height: 38px;
          font-size: 28px;
          letter-spacing: -0.42px;
        }

        .transactions-cards-overflow {
          .transactions-cards {
            grid-template-columns: repeat(3, 1fr);
            gap: 25px;
          }
        }
      }
    }

    .transactions-right-side {
      width: 320px;
      padding-right: 48px;
    }
  }
}

@media (min-width: $screen-lg) {
  .transactions-wrapper {
    .transactions-left-side {
      margin-left: 94px;

      .main-component {
        padding-top: 48px;
        margin: 0 34px;
        gap: 26px;
        height: calc(100vh - 100px);

        & > h4 {
          line-height: 42px;
          font-size: 38px;
          letter-spacing: -0.57px;
        }

        .transactions-cards-overflow {
          .transactions-cards {
            gap: 32px;
          }
        }
      }
    }

    .transactions-right-side {
      padding-right: 62px;
    }
  }
}

// color variables

$white: #FFFFFF;
$black: #000000;
$lightBlack:#2B2B2B;
$nero:#1C1C1C;
$lightgreen: #6DC480;
$green:#6EC37F;
$sunshade:#FF9244;
$red:#F80000;
$burgundy:#F24343;
$gray:#666666;
$boldGray:#63636366;
$lightGray:#AAAAAA;
$veryLightGray:#E8E8E8;
$disabledButton:#EEEEEE;
$disabledButtonColor:#646464;
$milkColor: #D8DCD1;
$doveGray:#6A6A6A;
$stoneGray:#C4C4C4;
$brightGray:#EAEAEA;
// fonts variables

$BrandonGrotesque: 'Brandon Grotesque';
$brown: 'Brown';
$SFProtext:'SF UI Text';
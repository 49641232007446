/*#region Loader*/
.loader-holder{
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99999;
  background-color: #ffffffa1;
}
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: #A1A1A1;
  font-size: 6px;
  //margin: 20px auto 40px auto;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  position: absolute;
  //bottom: 3.4rem;
  pointer-events: none;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  transform: translateY(-90%);
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%, 80%, 100% {
      box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
      box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%, 80%, 100% {
      box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
      box-shadow: 0 2.5em 0 0;
  }
}

.landing-loading {
  height: 100vh;
  position: fixed;
  width: 100%;
  background-color: #28282830;
  z-index: 17;
  top: 0;
  pointer-events: none;
}

@media (min-width: $screen-sm) {
  .user-right-menu {
    padding-top: 48px;

    .user-main-info {
      padding-bottom: 20px;
    }

    .user-img {
      margin-bottom: 10px;
      height: 100px;
      width: 100px;

      .placeholder {
        font-size: 32px;
      }
    }

    .user-firstname {
      line-height: 29px;
      font-size: 20px;
    }

    .user-role {
      line-height: 20px;
      font-size: 13px;
    }

    .edit-btn {
      bottom: 8px;
    }
  }
  .user-form {
    padding: 28px 24px 32px;
  }
  .input-group {
    &:not(:last-of-type) {
      margin-bottom: 18px;
    }

    label {
      line-height: 18px;
      font-size: 13px;
    }

    .input-field {
      line-height: 18px;
      font-size: 15px;
      padding: 12px 0 7px;
    }

    .error-msg {
      line-height: 18px;
    }
  }
  .save-btn {
    &.sm {
      line-height: 24px;
      font-size: 16px;
      height: 56px;
    }
  }
  .user-right-log-out {
    padding: 32px 24px;

    .btn {
      line-height: 24px;
      font-size: 14px;
      height: 56px;
    }
  }
  .user-form-password {
    padding: 28px 24px 13px;

    input {
      padding: 8px 0;
    }
  }
}

@media (min-width: $screen-md) {
  .user-right-menu {
    padding-top: 20px;

    .user-main-info {
      padding-bottom: 20px;
    }

    .user-img {
      margin-bottom: 4px;
      height: 80px;
      width: 80px;
    }

    .user-firstname {
      font-size: 15px;
    }

    .user-role {
      font-size: 11px;
      line-height: 13px;
    }

    .user-img {
      .placeholder {
        font-size: 24px;
      }
    }

    .edit-btn {
      bottom: 0;
    }
  }
  .user-form {
    padding: 32px 24px 10px;

    input {
      padding: 8px 0 6px 0;
      line-height: 17px;
      font-size: 14px;
    }

    label {
      line-height: 13px;
      font-size: 11px;
    }

    .save-btn {
      margin-top: 22px;
    }
  }
  .input-group {
    &:not(:only-of-type) {
      margin-bottom: 10px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .user-form-password {
    padding: 32px 24px 22px;

    .input-group {
      &:not(:only-of-type) {
        margin-bottom: 20px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .input-field {
      line-height: 13px;
      font-size: 11px;
      padding: 7px 0;
    }

    label {
      line-height: 13px;
      font-size: 11px;
    }
  }
  .user-main-info {
    padding-bottom: 15px;
  }
  .user-right-log-out {
      padding: 26px 24px;
    .btn {
      line-height: 17px;
    }
  }
}

@media (min-width: $screen-lg) {
  .user-right-menu {
    padding-top: 56px;

    .user-img {
      margin-bottom: 20px;
      height: 120px;
      width: 120px;

      .placeholder {
        font-size: 36px;
      }
    }

    .user-main-info {
      padding-bottom: 22px;
    }

    .user-firstname {
      font-size: 20px;
    }

    .user-role {
      font-size: 13px;
      line-height: 20px;
    }
  }

  .user-form {
    label {
      line-height: 18px;
      font-size: 13px;
    }

    input {
      line-height: 18px;
      font-size: 15px;
      padding: 12px 0 6px;
    }
  }

  .input-group {
    &:not(:only-of-type) {
      margin-bottom: 12px;
    }
  }

  .user-form-password {
    padding: 28px 24px 14px;

    label {
      line-height: 18px;
      font-size: 13px;
    }

    input {
      padding: 12px 0 6px;
    }
  }

  .user-right-log-out {
      padding: 32px 24px;
    .btn {
      line-height: 24px;
    }
  }
}

@font-face {
  font-family: 'communicator';
  src:  url('../../fonts/icons/communicator.eot?ogm8lo');
  src:  url('../../fonts/icons/communicator.eot?ogm8lo#iefix') format('embedded-opentype'),
  url('../../fonts/icons/communicator.ttf?ogm8lo') format('truetype'),
  url('../../fonts/icons/communicator.woff?ogm8lo') format('woff'),
  url('../../fonts/icons/communicator.svg?ogm8lo#communicator') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'communicator' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-visibility:before {
  content: "\e905";
}
.icon-visibility-hide:before {
  content: "\e90d";
}
.icon-home:before {
  content: "\e914";
}
.icon-close-circle:before {
  content: "\e910";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-right:before {
  content: "\e911";
}
.icon-arrow-left:before {
  content: "\e912";
}
.icon-check:before {
  content: "\e901";
}
.icon-close:before {
  content: "\e902";
}
.icon-close-rounded:before {
  content: "\e903";
}
.icon-close-circle1:before {
  content: "\e904";
}
.icon-download:before {
  content: "\e906";
}
.icon-exit:before {
  content: "\e907";
}
.icon-info:before {
  content: "\e908";
}
.icon-info-phone:before {
  content: "\e909";
}
.icon-mail:before {
  content: "\e90a";
}
.icon-message:before {
  content: "\e90b";
}
.icon-pencil:before {
  content: "\e90c";
}
.icon-search:before {
  content: "\e90e";
}
.icon-watch:before {
  content: "\e90f";
}
.icon-users:before {
  content: "\e913";
}
.icon-email:before {
  content: "\e915";
}
.icon-phone:before {
  content: "\e916";
}
.icon-checkmark:before {
  content: "\e917";
}
.icon-back-to-your-dashboard:before {
  content: "\e918";
}
.icon-arrow-drop-down:before {
  content: "\e919";
}
@media (min-width: $screen-sm) {
  .task {
    padding: 18px 24px 18px 58px;

    &:before {
      height: calc(100% - 36px);
      left: 24px;
    }

    .task-info {
      flex-direction: row;
      align-items: center;
      padding-right: 86px;
      
      h5 {
        padding-right: 24px;

        i {
          color: #d2d2d2;
          font-size: 12px;
          top: 3px;
          right: 6px;
        }
      }
    }
  }

  .transaction-date {
    font-size: 12px;
    line-height: 26px;
  }
}

@media (min-width: $screen-md) {
  .task {
    &.in-progress {
      cursor: default;
    }

    .task-info {
      padding-right: 110px;
    }
  }
}

@media (min-width: $screen-lg) {
  .task {
    .task-icon {
      width: 40px;
      height: 40px;
      min-width: 40px;
      line-height: 40px;
    }

    .transaction-date {
      font-size: 13px;
      line-height: 26px;
    }

    .task-info {
      padding-right: 90px;
    }
  }
}

@media (min-width: $screen-sm) {
  .milestone-title {
    padding-bottom: 16px;
    padding-top: 24px;
    position: sticky;
    z-index: 2;
    top: 217px;
  }
  .transaction-milestones {
    .milestone-title {
      line-height: 18px;
      font-size: 14px;
    }
    .transaction-date {
      line-height: 15px;
      font-size: 12px;
    }
  }
}

@media (min-width: $screen-md) {
  .milestone-title {
    top: 248px;
  }
}

@media (min-width: $screen-lg) {
  .milestone-title {
    padding-bottom: 19px;
    top: 270px;
  }
  .transaction-milestones {
    .milestone-title {
      line-height: 26px;
      font-size: 16px;
    }
    .task {
      .transaction-date {
        line-height: 26px;
        font-size: 13px;
      }
    }
  }
}

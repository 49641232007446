@media (min-width: $screen-sm) {
  .flyout {
    .flyout-header {
      .flyout-title {
        font-size: 16px;
        line-height: 26px;
        min-height: 26px;
      }

      .flyout-close {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }

    .flyout-content {
      width: 480px;
      padding: 40px 32px;
    }
  }
}

@media (min-width: $screen-lg) {
  .flyout {
    .flyout-header {
      .flyout-title {
        font-size: 18px;
        line-height: 26px;
        min-height: 26px;
      }
    }

    .flyout-content {
      width: 548px;
      padding: 40px 46px;
    }
  }
}

@media screen and (min-width: $screen-sm) {
  h1 {
    line-height: 38px;
    font-size: 34px;
  }

  h3 {
    line-height: 24px;
    font-size: 18px;
  }

  h4 {
    line-height: 24px;
    font-size: 16px;
  }

  h5 {
    line-height: 24px;
    font-size: 16px;
  }

  h6 {
    line-height: 24px;
    font-size: 15px;
  }
}

@media screen and (min-width: $screen-lg) {
  h1 {
    line-height: 42px;
    font-size: 38px;
  }

  h2 {
    line-height: 42px;
    font-size: 30px;
  }

  h3 {
    line-height: 26px;
    font-size: 22px;
  }

  h4 {
    line-height: 24px;
    font-size: 20px;
  }

  h5 {
    line-height: 26px;
    font-size: 18px;
  }

  h6 {
    line-height: 26px;
    font-size: 16px;
  }
}

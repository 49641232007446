@media (min-width: $screen-sm) {
  .modal-container {
    &.xs {
      width: 320px;

      .modal-title {
        letter-spacing: -0.05px;
        padding-bottom: 10px;
        margin-bottom: 15px;
        line-height: 22px;
        font-size: 16px;
      }

      .modal-description {
        margin-bottom: 14px;
        line-height: 21px;
        font-size: 14px;
      }

      .message-field {
        margin-bottom: 16px;
        height: 96px;
      }
    }
    &.sm {
      padding: 32px 32px 40px;
      width: 375px;

      .modal-title {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.63px;
        margin-bottom: 16px;
      }

      .modal-description {
        line-height: 21px;
        font-size: 14px;
      }
    }
  }
}

@media (min-width: $screen-md) {
  .modal-container {
    &.xs {
      padding: 23px 30px 30px;

      .modal-close {
        right: 16px;
        top: 16px;
      }
    }
  }
}
.agent-header {
  background-image: url("../../images/banner-default-Q3.jpg");
  padding-bottom: 16px;
  padding-top: 16px;
}

.agent-banner {
  border-radius: $border-radius-xs;
  background-color: $color-white;
  box-shadow: $shadow-md;
  align-items: center;
  padding: 8px 12px;
  display: flex;
}

.agent-banner-info {
  max-width: calc(100% - 100px);
}

.agent-sticky-bar {
  position: sticky;
  z-index: 9;
  top: 68px;
}

.agent-image {
  border-radius: $border-radius-xs;
  margin-right: 12px;
  overflow: hidden;
  flex-shrink: 0;
  height: 64px;
  width: 64px;
}

.agent-title {
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 28px;
  overflow: hidden;
  font-size: 20px;
  max-width: 100%;
}

.agent-sup-info {
  letter-spacing: 1.1px;
  color: $text-light;
  line-height: 13px;
  font-size: 11px;
}

.agent-sub-info {
  color: $text-light;
  line-height: 14px;
  max-width: 245px;
  font-size: 11px;
}

.transactions-header {
  background-color: $bg-light;
  margin-top: 16px;
  padding: 0 16px 12px;
  display: flex;

  p {
    font-family: $font-secondary;
    text-transform: uppercase;
    letter-spacing: -0.03px;
    color: $text-light;
    line-height: 12px;
    font-size: 10px;
  }
}

.transactions-list-width-order {
  justify-content: space-between;
  align-items: center;
  display: flex;

  li {
    &:nth-child(1) {
      width: 80%;
    }

    &:last-child {
      width: 20%;
    }
  }
}

@media (min-width: $screen-sm) {
  .hide-completed {
    padding-right: 20px;
    line-height: 15px;
    position: sticky;
    font-size: 13px;
    height: 0;

    &.inside-transaction {
      top: 24px;
    }
    &.inside-progress {
      transform: translateY(30px);
      top: 217px;
    }
  }
}

@media (min-width: $screen-md) {
  .hide-completed {
    &.inside-progress {
      transform: translateY(35px);
      padding-right: 32px;
      top: 248px;
    }
  }
}

@media (min-width: $screen-lg) {
  .hide-completed {
    &.inside-progress {
      top: 270px;
    }
  }
}

.btn-1:not(.disabled){
    position:relative;
    display:inline-block;
    background-color: $black;
    color:$white;
    &:after{
        content:"";
        position: absolute;
        display:block;
        left: 0;
        bottom:0;
        height: 3px;
        background-color: $green;
        width: 0%;
        transition: width 300ms ease-out;
        -webkit-transition: width 300ms ease-out;
        -moz-transition: width 300ms ease-out;
        -ms-transition: width 300ms ease-out;
        -o-transition: width 300ms ease-out;
}
    &:hover:after{
         width: 100%;
    }
}

.btn-2:not(.disabled){
    border: 1px solid $black;
}

.btn-2.logout{
    padding: 13px 18px;
    color: $doveGray;
    line-height: 17px;
    font-size: 14px;

    & i {
        color: #1C1C1C;
        margin-right: 12px;
        font-size: 20px;
    }

    @media (min-width: 758px) {
        line-height: 24px;
        padding: 15px 18px;
    }
}

.logout-btn-inner{
    display: flex;
}

.btn.save-changes.sm {
    font-size: 14px;
    line-height: 17px;
    font-family: "Brown Regular", sans-serif;
    
    @media (min-width: 758px) {
        height: 56px;
    }
}
@media (min-width: $screen-sm) {
  // flyout part styles
  .history-body {
    .popup-chart {
      width: 172px;
      height: 172px;
      margin: 25px auto 50px;

      .price {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0px;
      }
    }

    .title-description {
      .title {
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.7px;
        margin-bottom: 10px;
      }
      .description {
        line-height: 22px;
      }
    }

    .history-collapse-panel {
      padding: 22px 24px;

      .divider {
        margin: 21px auto;
      }
      li > div {
        span.bold_in_mobile {
          font-family: $font-primary;
        }
      }
    }
  }
}

@media (min-width: $screen-md) {
  .card-chart {
    &.card {
      padding: 30px 32px 26px;
      margin-bottom: 28px;
    }
  }
  .history-section-chart {
    cursor: default;
    margin: auto;
    width: 104px;
    height: 104px;

    .price {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.63px;
    }

    canvas {
      background: none;
    }

    &.blurred {
      filter: blur(5px);
    }
  }

  .title-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px auto 16px;
    text-align: center;

    .title {
      font-family: $font-tertiary;
      text-transform: uppercase;
      font-size: 18px;
      letter-spacing: 0.63px;
      line-height: 24px;
      margin-bottom: 2px;
    }
    .description {
      font-family: $font-secondary;
      color: $text-light;
      font-size: 14px;
      line-height: 22px;
    }

    &.moreSpace{
      margin-top: 28px;

      .title{
        margin-bottom: 8px;
      }
    }
  }
}

@media (min-width: $screen-lg) {
  .card-chart {
    &.card {
      padding: 42px 32px 40px;
      margin-bottom: 48px;
    }
  }
  .history-section-chart {
    margin: auto;
    width: 144px;
    height: 144px;

    .price {
      font-size: 22px;
      line-height: 26px;
      letter-spacing: 0.77px;
    }

    canvas {
      // width: 100% !important;
      // height: 100% !important;
    }
  }

  .title-description {
    margin: 20px auto 22px;

    .title {
      font-size: 22px;
      line-height: 26px;
      letter-spacing: 0.77px;
      margin-bottom: 8px;
    }
    .description {
      font-size: 16px;
      line-height: 26px;
    }

    &.moreSpace{
      margin: 36px auto 28px;
    }
  }

  // flyout part styles
  .history-body {
    .popup-chart {
      width: 200px;
      height: 200px;
      margin: 54px auto 64px;

      .price {
        font-size: 30px;
        line-height: 28px;
        letter-spacing: 0.77px;
      }
    }

    // flyout part styles
    .history-collapse-panel {
      padding: 26px 24px;

      .divider {
        margin: 25px auto 22px;
      }

      li > div {
        div {
          font-size: 12px;
          line-height: 13px;
        }

        span {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
}

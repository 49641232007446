.unauthorized-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $color-gray99;

  svg {
    width: 72px;
    height: 70px;
  }

  h4 {
    font-family: $font-tertiary;
    text-transform: uppercase;
    margin-top: 18px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 8px;
    font-family: $font-secondary;
    font-size: 13px;
    line-height: 21px;
  }

  a {
    color: $color-secondary;
    font-family: $font-secondary;
    font-size: 13px;
    line-height: 21px;
  }
}
